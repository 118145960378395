import { AnyAction } from 'redux';
import { CommandActionTypes, CommandsState } from './command.types';

export const initialState: CommandsState = {
	command: {},
	commandId: 1,
	commandInc: 1,
	commandLog: [],
	mapIdToActionId: {}
};

export const commandsReducer = (state = initialState, action: AnyAction): CommandsState => {
	switch (action.type) {
		case CommandActionTypes.setCommand: {
			return {
				...state,
				command: action.payload
			};
		}
		case CommandActionTypes.incrementComandId: {
			const next = {};
			if (action.payload !== '') {
				next[state.commandId.toString()] = action.payload;
			}

			return {
				...state,
				mapIdToActionId: { ...state.mapIdToActionId, ...next },
				commandId: state.commandId + 1
			};
		}
		case CommandActionTypes.incrementCommandInc: {
			return {
				...state,
				commandInc: state.commandInc + 1
			};
		}
		case CommandActionTypes.addToCommandLog: {
			return {
				...state,
				commandLog: state.commandLog.concat(action.payload)
			};
		}
		case CommandActionTypes.resetCommandLog: {
			return {
				...state,
				commandLog: []
			};
		}
		case CommandActionTypes.addCommandsAsBulk: {
			let idAmount = 0;
			let incAmount = 0;
			const newCommands = [];
			// TODO proper types, we dont know those keys type yet
			const nextMap = {};
			if (Array.isArray(action.payload)) {
				action.payload.forEach(command => {
					if (!command.value.isModified && !command.value.isCancelled) {
						if (command.actionId) {
							nextMap[state.commandId + idAmount] = command.actionId;
						}
						idAmount++;
					}
					incAmount++;
					newCommands.push(command);
				});
			}

			return {
				...state,
				commandLog: newCommands,
				commandId: state.commandId + idAmount,
				commandInc: state.commandInc + incAmount,
				mapIdToActionId: { ...state.mapIdToActionId, ...nextMap }
			};
		}
		default:
			return state;
	}
};
