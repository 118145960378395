import { lazy } from 'react';

import { statusNames as basketballStatusNames } from 'pages/Basketball/Basketball.constants';
import { statusNames as footballStatusNames } from 'pages/Football/Football.constants';
import { statusNames as volleyballStatusNames } from 'pages/Volleyball/Volleyball.constants';
import { statusNames as cricketStatusNames } from 'pages/Cricket/Cricket.constants';
import { statusNames as tableTennisStatusNames } from 'pages/TableTennis/TableTennis.constants';
import { statusNames as iceHockeyStatusNames } from 'pages/IceHockey/IceHockey.constants';
import { getBaseballStatusName } from 'pages/Baseball/Baseball.utils';
import { statusNames as handballStatusNames } from 'pages/Handball/Handball.constants';
import { statusNames as futsalStatusNames } from 'pages/Futsal/Futsal.constants';
import { statusNames as rugbyleagueStatusNames } from 'pages/RugbyLeague/RugbyLeague.constants';
import { statusNames as americanFootballStatusNames } from 'pages/AmericanFootball/AmericanFootball.constants';
import { statusNames as snookerStatusNames } from 'pages/Snooker/Snooker.constants';
import { rugbyUnionScoreToStatusMap as rugbyunionStatusNames } from 'pages/RugbyUnion/RubgyUnion.constants';
import { statusNames as tennisStatusNames } from 'pages/Tennis/Tennis.utils';
import { statusNames as aussieRulesStatusNames } from 'pages/AussieRules/AussieRules.utils';
import { statusNames as dartsStatusNames } from 'pages/Darts/Darts.utils';
import { statusNames as motorsportStatusNames } from 'pages/Motorsport/Motorsport.constants';

const TennisTable = lazy(() => import('../../pages/TableTennis/TableTennis'));
const Football = lazy(() => import('../../pages/Football/Football'));
const Basketball = lazy(() => import('../../pages/Basketball/Basketball'));
const Volleyball = lazy(() => import('../../pages/Volleyball/Volleyball'));
const IceHockey = lazy(() => import('../../pages/IceHockey/IceHockey'));
const RugbyLeague = lazy(() => import('../../pages/RugbyLeague/RugbyLeague'));
const RugbyUnion = lazy(() => import('../../pages/RugbyUnion/RugbyUnion'));
const Baseball = lazy(() => import('../../pages/Baseball/Baseball'));
const AmericanFootball = lazy(() => import('../../pages/AmericanFootball/AmericanFootball'));
const Tennis = lazy(() => import('../../pages/Tennis/Tennis'));
const AussieRules = lazy(() => import('../../pages/AussieRules/AussieRules'));
const Futsal = lazy(() => import('../../pages/Futsal/Futsal'));
const Handball = lazy(() => import('pages/Handball/Handball'));
const Snooker = lazy(() => import('../../pages/Snooker/Snooker'));
const Darts = lazy(() => import('../../pages/Darts/Darts'));
const Cricket = lazy(() => import('../../pages/Cricket/Cricket'));
const Motorsport = lazy(() => import('../../pages/Motorsport/Motorsport'));

export const sportIds = {
	tabletennis: '269467',
	darts: '8',
	football: '10',
	basketball: '4',
	volleyball: '91189',
	icehockey: '15',
	rugbyleague: '73743',
	rugbyunion: '73744',
	baseball: '3',
	americanfootball: '17',
	tennis: '24',
	australianrules: '2',
	futsal: '491393',
	handball: '99614',
	snooker: '22',
	cricket: '6',
	motorsport: '16'
};

export const sportsMapping = {
	tabletennis: { component: TennisTable },
	darts: { component: Darts },
	football: { component: Football },
	basketball: { component: Basketball },
	volleyball: { component: Volleyball },
	icehockey: { component: IceHockey },
	rugbyleague: { component: RugbyLeague },
	rugbyunion: { component: RugbyUnion },
	baseball: { component: Baseball },
	americanfootball: { component: AmericanFootball },
	tennis: { component: Tennis },
	australianrules: { component: AussieRules },
	futsal: { component: Futsal },
	handball: { component: Handball },
	snooker: { component: Snooker },
	cricket: { component: Cricket },
	motorsport: { component: Motorsport }
};

export const statusNameValues = {
	Abandoned: 'Abandoned',
	WinnerCompetitorOneByRetirementOrDefault: 'Winner by default - Home',
	WinnerCompetitorTwoByRetirementOrDefault: 'Winner by default - Away',
	WalkoverCompetitorOne: 'Walkover Competitor - Home',
	WalkoverCompetitorTwo: 'Walkover Competitor - Away'
};

// Test event ID can be either "test" or "test1" (test + number between 1 and 1000)
export const testEventIdRegex = /^test([1-9][0-9]{0,2}|1000)?$/;
export const testAutomationEventIdRegex = /^test_automation?$/;
export const realEventIdRegex = /^\d+$/;

export const getSportStatusName = (sport: string, statusId: number): string => {
	if ([101, 102, 103, 104, 105].includes(statusId)) {
		return 'Match Status';
	}

	switch (sport) {
		case 'tabletennis': return tableTennisStatusNames.display[statusId];
		case 'football': return footballStatusNames.display[statusId];
		case 'basketball': return basketballStatusNames.display[statusId];
		case 'volleyball': return volleyballStatusNames.display[statusId];
		case 'icehockey': return iceHockeyStatusNames.display[statusId];
		case 'baseball': return getBaseballStatusName(Number(statusId));
		case 'handball': return handballStatusNames.display[statusId];
		case 'futsal': return futsalStatusNames.display[statusId];
		case 'rugbyleague': return rugbyleagueStatusNames[statusId].display;
		case 'americanfootball': return americanFootballStatusNames.display[statusId];
		case 'snooker': return snookerStatusNames.display[statusId];
		case 'rugbyunion': return rugbyunionStatusNames[statusId].display;
		case 'tennis': return tennisStatusNames.display[statusId];
		case 'australianrules': return aussieRulesStatusNames.display[statusId];
		case 'darts': return dartsStatusNames.display[statusId];
		case 'cricket': return cricketStatusNames.display[statusId];
		case 'motorsport': return motorsportStatusNames.display[statusId];
		default: return null;
	}
};

export const selectAdditionalStatusesBySport = (sport: string): any => {
	switch (sport) {
		case 'tabletennis': return [
			{ WalkoverCompetitorOne: 'Walkover Competitor - Home' },
			{ WalkoverCompetitorTwo: 'Walkover Competitor - Away' }
		];
		default: return null;
	}
};
