import { makeStyles } from '@material-ui/core';

export const useSRTNavBarStyles = makeStyles({
	root: {
		'& .MuiAppBar-root': {
			alignItems: 'center'
		}
	},
	logo: {
		height: '2.5rem',
		width: 'auto',
		margin: '0 16px'
	}
});
