import { realEventIdRegex, sportsMapping, testEventIdRegex, testAutomationEventIdRegex } from './constants';

const notEmpty = value => value !== '';
const positiveInteger = (value: number): boolean => value && value > -1;
const urlParamsValidators =(urlParamKey: string, urlParamValue: string): boolean => {
	switch (urlParamKey) {
		case 'eventId': return realEventIdRegex.test(urlParamValue)
			|| testEventIdRegex.test(urlParamValue)
			|| testAutomationEventIdRegex.test(urlParamValue);
		case 'sport': return sportsMapping[urlParamValue];
		case 'page': return urlParamValue === 'resulting';
		default: return false;
	}
};

export {
	notEmpty,
	positiveInteger,
	urlParamsValidators
};
