export type TennisBasicScore = {
    [key in TennisStatusIdKeys]: DataPoint
}

export type TennisScore = TennisBasicScore | {
    [key: string] : DataPoint
}

export enum TennisStatusIdKeys {
    MATCH_SCORE = 111,
    CURRENT_GAME_SCORE = 100
}
