import React from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@material-ui/core';

import { useSearchParams } from 'common/hooks/useSearchParams';
import { sendData } from 'common/utils/socket.utils';
import { selectCommandsId, selectCommandsInc } from 'store/command/command.selectors';
import { selectAuthState } from 'store/auth/auth.selectors';
import { sportIds } from 'common/utils/constants';

interface ConfirmResetDialogProps {
	dismiss: () => void;
}

const ConfirmResetDialog = ({ dismiss }: ConfirmResetDialogProps) => {
	const { username } = useSelector(selectAuthState);

	const { eventId, sport } = useSearchParams();

	const commandId = useSelector(selectCommandsId);
	const commandInc = useSelector(selectCommandsInc);

	const handleReset = () => {
		const obj = {
			type: 'command',
			user: username,
			page: 'resulting',
			value: {
				'sport_id': sportIds[sport],
				id: commandId,
				'event_id': eventId,
				UTCtimestamp: new Date().toISOString(),
				feedAction: 'reset',
				feed: {
					'sequence': commandInc,
					'isConfirmed': true,
					'utcTimestamp': '2021-04-19T08:05:34.255Z'
				}
			}
		};

		sendData(obj);
		dismiss();
	};

	return (
		<>
			<DialogTitle data-cy="confirm-reset-dialog-title">Are you sure to reset the event?</DialogTitle>
			<DialogContent>
				<DialogContentText data-cy="confirm-reset-dialog-text">
					You are about to clear the data for this event. Are you sure you really want to do it?
				</DialogContentText>
			</DialogContent>
			<DialogActions data-cy="confirm-reset-dialog-actions">
				<Button
					aria-label="confirm"
					onClick={handleReset}
					variant="contained"
					color="primary"
					disableElevation
					data-cy="confirm-reset-dialog-yes"
				>
					Yes
				</Button>
				<Button onClick={dismiss} variant="outlined" color="primary" disableElevation data-cy="confirm-reset-dialog-no">
					No
				</Button>
			</DialogActions>
		</>
	);
};

export default ConfirmResetDialog;
