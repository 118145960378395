import React from 'react';

import { Score, CricketDataPointsNames } from 'pages/Cricket/Cricket.types';

export const tableHeaders = [
	<span>1st Inning Result</span>,
	<span>2nd Inning Result</span>,
	<span>Highest Opening Partnership</span>,
	<span>Run Outs</span>,
	<span>Final Result</span>
];

export const statusNames = {
	msf: {
		1: 'FirstInningResult',
		2: 'SecondInningResult',
		3: 'HighestOpeningPartnership',
		4: 'RunOuts',
		111: 'FinalResult'
	},
	display: {
		1: '1st Inning Result',
		2: '2nd Inning Result',
		3: 'Highest Opening Partnership',
		4: 'Run Outs',
		111: 'Final Result'
	}
};

export const scoreToStatusMap = {
	1: { msf: 'FirstInningResult', display: '1st Inning Result' },
	2: { msf: 'SecondInningResult', display: '2nd Inning Result' },
	3: { msf: 'HighestOpeningPartnership', display: 'Highest Opening Partnership' },
	4: { msf: 'RunOuts', display: 'Run Outs' },
	111: { msf: 'FinalResult', display: 'Final Result' }
};

export const scoreToStatusId = [1, 2, 3, 4, 111];

export const initialScoreState: Score = [
	{
		dataPoint: CricketDataPointsNames.firstInningsResult,
		statusId: 1,
		result: [-1, -1]
	}, {
		dataPoint: CricketDataPointsNames.secondInningsResult,
		statusId: 2,
		result: [-1, -1]
	}, {
		dataPoint: CricketDataPointsNames.highestOpeningPartnership,
		statusId: 3,
		result: [-1, -1]
	}, {
		dataPoint: CricketDataPointsNames.runOuts,
		statusId: 4,
		result: [-1, -1]
	}, {
		dataPoint: CricketDataPointsNames.finalResult,
		statusId: 111,
		result: [-1, -1]
	}
];

