export type Result = [number, number];

export interface ScoreItem {
	dataPoint: string,
	statusId: number,
	result: Result
}

export type Score = ScoreItem[]

/* eslint-disable camelcase */
export interface CricketDataPointsInterface {
	highest_opening_partnership: boolean,
	players_information: boolean,
	run_outs: boolean,
	first_innings_result: boolean,
	second_innings_result: boolean,
	final_result: boolean
}
/* eslint-enable camelcase */

export enum CricketDataPointsNames {
	highestOpeningPartnership = 'highest_opening_partnership',
	playersInformation = 'players_information',
	runOuts = 'run_outs',
	firstInningsResult = 'first_innings_result',
	secondInningsResult = 'second_innings_result',
	finalResult = 'final_result'
}

export interface LineUpsItem {
	$type: string;
	internalId: string;
	playerPosition: string;
	position: any;
	shirtNumber: number;
	sourceId: string;
	sourceName: string;
}

export interface LineUpsData {
    home: {
        in: any,
        out: any
    },
	away: {
		in: any,
		out: any
	}
}

export interface StatisticType {
	player: string;
	wickets: string;
	runs: string | number;
	sixes: string | number;
	type: string,
}

export interface StatisticList {
	home: {
		[key: number]: StatisticType[]
	},
	away: {
		[key: number]: StatisticType[]
	},
}

export interface ScoresData {
	score: Score,
	goalsList: StatisticList
}

export type InEditMode = {
	status: boolean,
	columnKey: number
}

export enum CricketCommandValueKey {
	gameScore = 'game_score',
	finalResult = 'final_result'
}

export enum CricketCommandKey {
	gameScore = 'gamescore',
	finalResult = 'finalresult'
}

/* eslint-disable camelcase */
export interface CricketCommandInterface {
	value: {
		id: number,
		key: CricketCommandKey.finalResult | CricketCommandKey.gameScore,
		sport_id: string,
		value: Partial<{
			[key in CricketCommandValueKey]: number[]
		}>,
		feedAction: string,
		feed: {
			gameNumber: string,
			scoreInPoints: {
				competitorOne: number,
				competitorTwo: number
			}
		},
		status_id: string,
	}
}

export interface StatisticDataInterface {
	wickets: string,
	runs: string | number,
	sixes: string | number,
	label?: string,
	phase: string | number,
	player: string
}

