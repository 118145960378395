import React from 'react';
import { AmericanFootballKeys, AmericanFootballScore } from './AmericanFootball.types';

export const initialScoreState: AmericanFootballScore = {
	[AmericanFootballKeys.FirstQuarterScore]: { dataPoint: '1st_quarter_score', result: [-1, -1] },
	[AmericanFootballKeys.SecondQuarterScore]: { dataPoint: '2nd_quarter_score', result: [-1, -1] },
	[AmericanFootballKeys.FirstHalfScore]: { dataPoint: 'first_half_score', result: [-1, -1] },
	[AmericanFootballKeys.ThirdQuarterScore]: { dataPoint: '3rd_quarter_score', result: [-1, -1] },
	[AmericanFootballKeys.FourthQuarterScore]: { dataPoint: '4th_quarter_score', result: [-1, -1] },
	[AmericanFootballKeys.SecondHalfScore]: { dataPoint: 'second_half_score', result: [-1, -1] },
	[AmericanFootballKeys.RegularTimeScore]: { dataPoint: 'regular_time_score', result: [-1, -1] },
	[AmericanFootballKeys.OverTimeScore]: { dataPoint: 'overtime_score', result: [-1, -1] },
	[AmericanFootballKeys.FinalScore]: { dataPoint: 'final_score', result: [-1, -1] }
};

export const tableHeaders = [
	<span>1st<br/>Quarter</span>,
	<span>2nd<br/>Quarter</span>,
	<span>1st<br/>Half</span>,
	<span>3rd<br/>Quarter</span>,
	<span>4th<br/>Quarter</span>,
	<span>2nd<br/>Half</span>,
	<span>RegularTime<br/>Score</span>,
	<span>OverTime<br/>Score</span>,
	<span>Final<br/>Score</span>
];

export const statusNames = {
	msf: {
		1: 'FirstQuarter',
		2: 'SecondQuarter',
		3: 'FirstHalf',
		4: 'ThirdQuarter',
		5: 'FourthQuarter',
		6: 'SecondHalf',
		111: 'RegularTimeScore',
		112: 'OverTimeScore',
		113: 'FinalScore'
	},
	display: {
		1: '1st Quarter',
		2: '2nd Quarter',
		3: '1st Half',
		4: '3rd Quarter',
		5: '4th Quarter',
		6: '2nd Half',
		111: 'RegularTime Score',
		112: 'OverTime Score',
		113: 'Final Score'
	}
};

export const statusIdByKey = {
	'1st_quarter_score': 1,
	'2nd_quarter_score': 2,
	'first_half_score': 3,
	'3rd_quarter_score': 4,
	'4th_quarter_score': 5,
	'second_half_score': 6,
	'regular_time_score': 111,
	'overtime_score': 112,
	'final_score': 113
};

export const scorePointsByType = {
	Touchdown: { label: 'Touchdown', amount: 6 },
	FieldGoal: { label: 'Field Goal', amount: 3 },
	Safety: { label: 'Safety', amount: 2 },
	OnePointConversion: { label: '1p Conversion', amount: 1 },
	TwoPointConversion: { label: '2p Conversion', amount: 2 }
};
