export interface UIState {
    loadingOverlayEnabled: boolean,
    isControlTaken: boolean,
    controlledBy: string,
	actionButtonsDisabled: boolean,
	sendStatus: string
}

export interface ControlTakenPayload {
	isControlTaken: boolean,
	controlledBy: string
}

export const UIActionTypes = {
	toggleLoadingOverlay: '@ui/enableLoadingOverlay',
	setControlTaken: '@ui/setControlTaken',
	toggleActionButtons: '@ui/toggleActionButtons',
	setSendStatus: '@ui/setSendStatus'
};
