import { AnyAction } from 'redux';
import { AuthActionTypes, AuthState } from './auth.types';

export const initialState: AuthState = { authorized: false, username: null, token: null, organizationId: null };

export const authReducer = (state = initialState, action: AnyAction): AuthState => {
	switch (action.type) {
		case AuthActionTypes.setAuthorized: {
			return {
				...state,
				authorized: true
			};
		}
		case AuthActionTypes.setUsername: {
			return {
				...state,
				authorized: action.payload ? true : false,
				username: action.payload
			};
		}
		case AuthActionTypes.setAuth0Token: {
			return {
				...state,
				token: action.payload
			};
		}
		case AuthActionTypes.setOrganizationId: {
			return {
				...state,
				organizationId: action.payload
			};
		}
		default:
			return state;
	}
};
