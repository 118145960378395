import { makeStyles } from '@material-ui/core';

export const SRTControlledByStyles = makeStyles(theme => ({
	paper: {
		minHeight: 38,
		color: theme.palette.text.secondary,
		borderRadius: 0,
		boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%)',
		background: 'transparent',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 10
	}
}));
