import { makeStyles } from '@material-ui/core/styles';

export const useSRTFixtureTable = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'center'
	},
	container: {
		maxWidth: '95%'
	},
	table: {
		overflow: 'auto'
	},
	tableHeaderCells: {
		fontWeight: theme.typography.h1.fontWeight,
		fontSize: theme.typography.h1.fontSize
	},
	tableTeamCell: {
		fontSize: theme.typography.h1.fontSize,
		padding: '0.625rem',
		maxWidth: '3.125rem'
	},
	sticky: {
		position: 'sticky',
		left: 0,
		right: 0,
		background: 'white',
		zIndex: 2
	}
}));
