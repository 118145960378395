import { makeStyles } from '@material-ui/core';

export const useSRTLineupsStyles = makeStyles(() => ({
	root: {
		marginBottom: '1rem'
	},
	leftButton: {
		marginRight: '0.313rem'
	},
	rightButton: {
		marginLeft: '0.313rem'
	}
}));
