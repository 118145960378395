import { combineReducers } from 'redux';
import { authReducer } from './auth/auth.reducer';
import { commandsReducer } from './command/command.reducer';
import { sportReducer } from './sport/sport.reducer';
import { uiReducer } from './ui/ui.reducer';
import { dialogReducer } from './dialog/dialog.reducer';
import { configReducer } from './config/config.reducer';

const rootReducer = combineReducers({
	auth: authReducer,
	ui: uiReducer,
	sport: sportReducer,
	commands: commandsReducer,
	dialog: dialogReducer,
	config: configReducer
});

export default rootReducer;
