import { createSelector } from 'reselect';
import { RootState } from 'store';

export const selectSportState = (state: RootState) => state.sport;

export const selectInitialLoad = createSelector([selectSportState], state => state.initialLoad);

export const selectFixtureData = createSelector([selectSportState], state => state.fixtureData);

export const selectMatchStateData = createSelector([selectSportState], state => state.matchState);
