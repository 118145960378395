import React from 'react';

const SRTTabPanel = props => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			aria-labelledby={`wrapped-tab-${index}`}
			{...other}
			data-cy="srt-tab-panel"
		>
			{value === index && (
				children
			)}
		</div>
	);
};

export default SRTTabPanel;

