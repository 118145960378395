import React from 'react';
import { TableCell, ButtonGroup } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import { SRTIconButton } from '../../..';
import { useSRTActionsCellStyles } from './SRTActionsCellStyles';

interface SRTActionsCellProps {
	id: string;
	inEditMode: boolean;
	isDisabledSave: boolean;
	isDisabledEdit: boolean;
	isTakenControl: boolean;
	handleEdit: () => void;
	handleSave: () => void;
	handleCancel: () => void;
	variant?: 'head' | 'body' | 'footer';
	align?: any;
}

const SRTActionsCell = ({
	id,
	inEditMode,
	isDisabledSave,
	isDisabledEdit,
	isTakenControl,
	handleEdit,
	handleSave,
	handleCancel,
	align = 'center',
	variant
}: SRTActionsCellProps) => {
	const classes = useSRTActionsCellStyles();

	return (
		<TableCell data-testid={id} id={id} variant={variant} align={align} className={classes.root}>
			{inEditMode ? (
				<ButtonGroup>
					<SRTIconButton
						clickHandler={handleSave}
						icon={<SaveOutlinedIcon />}
						disabled={isDisabledSave}
						tooltipTitle="Save"
						size="large"
						ariaLabel="fixture-save"
						dataCy="srt-action-cell-save"
					/>
					<SRTIconButton
						clickHandler={handleCancel}
						icon={<CancelOutlinedIcon />}
						tooltipTitle="Cancel"
						size="large"
						ariaLabel="fixture-cancel"
						dataCy="srt-action-cell-cancel"
					/>
				</ButtonGroup>
			) : (
				<SRTIconButton
					clickHandler={handleEdit}
					icon={<EditOutlinedIcon />}
					disabled={isDisabledEdit}
					tooltipTitle={!isTakenControl && 'Please TAKE CONTROL first!'}
					ariaLabel="fixture-edit"
					dataCy={id}
				/>
			)}
		</TableCell>
	);
};

export default SRTActionsCell;
