import React from 'react';
import { TableCell, Input } from '@material-ui/core';

import { useSRTInputCellStyles } from './SRTInputCellStyles';

export interface SRTInputCellProps {
	id: string;
	value: string | number;
	sticky?: boolean;
	inEditMode: boolean;
	handleChange?: (value: number) => void;
	handleSave?: () => void;
	handleCancel?: () => void;
	isDisabledSave?: boolean;
	className?: string;
	valueClassName?: string;
	variant?: 'head' | 'body' | 'footer';
	align?: any;
}

const SRTInputCell = ({
	id,
	value,
	inEditMode,
	handleChange,
	handleSave,
	handleCancel,
	isDisabledSave,
	className = '',
	valueClassName,
	variant,
	align
}: SRTInputCellProps) => {
	const classes = useSRTInputCellStyles();

	return (
		<TableCell data-testid={id} id={id} className={className} variant={variant} align={align} data-cy={`${id}-cell`}>
			{inEditMode ? (
				<Input
					type="number"
					value={value}
					classes={classes}
					onChange={e => {
						const value = e.target.value;
						handleChange(value !== '' && parseInt(value) >= 0 ? parseInt(value) : -1);}
					}
					onKeyUp={e => {
						if (e.key === 'Enter' && !isDisabledSave) {
							handleSave();
						}
					}}
					onKeyDown={e => {
						if (e.key === 'Escape') {
							handleCancel();
						}
					}}
					disableUnderline
					inputProps={{
						'data-cy': id
					}}
				/>
			) : (
				<div className={valueClassName} data-cy="srt-input-cell-value">{value}</div>
			)}
		</TableCell>
	);
};

export default SRTInputCell;
