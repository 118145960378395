export enum RugbyLeagueKeys {
	FirstHalfScore = '1',
	SecondHalfScore = '2',
	FullScore = '111'
}

export type RugbyLeagueScore = { [key in RugbyLeagueKeys]: { dataPoint: string; result: Result; }; };

export type Result = [number, number];

/* eslint-disable camelcase */
export interface RugbyLeagueDataPointsInterface {
	first_half_score: boolean,
	second_half_score: boolean,
	regular_time_score: boolean,
	goal_types: boolean
}
/* eslint-enable camelcase */

export interface GoalType {
	minute: string | number,
	type: string
}

export interface GoalsList {
	home: {
		[key: number]: GoalType[]
	},
	away: {
		[key: number]: GoalType[]
	},
}

export type InEditMode = {
	status: boolean,
	columnKey: string
};
