import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormControl, Select, MenuItem, Grid, Button, InputLabel } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { showDialog } from 'store/dialog/dialog.actions';
import { selectLastMatchStatusFromCommands } from 'store/command/command.selectors';
import { toggleActionButtons, setSendStatus } from 'store/ui/ui.actions';

import { useSRTStatusControlStyles } from './SRTStatusControlStyles';
import { selectSendStatusState } from './../../store/ui/ui.selectors';
import { useSearchParams } from '../../common/hooks/useSearchParams';
import { selectAdditionalStatusesBySport } from 'common/utils/constants';

interface SRTStatusControlProps {
	disabled?: boolean;
}

const SRTStatusControl = ({ disabled = false }: SRTStatusControlProps) => {
	const [status, setStatus] = useState<any>('');

	const statusFromCommand = useSelector(selectLastMatchStatusFromCommands);
	const sendStatus = useSelector(selectSendStatusState);
	const dispatch = useDispatch();
	const { sport } = useSearchParams();

	const additionalStatusesHtml = (sport: string) => {
		const arr = [];
		const additionalStatusesBySport = selectAdditionalStatusesBySport(sport);
		additionalStatusesBySport !== null && additionalStatusesBySport.forEach((statusObj, key) => {
			arr.push(
				<MenuItem key={'status-' + key} value={Object.keys(statusObj)[0]} data-cy="srt-status-control-menu-item">
					{Object.values(statusObj)[0]}
				</MenuItem>
			);
		});

		return arr;
	};

	const additionalStatuses = additionalStatusesHtml(sport);

	const classes = useSRTStatusControlStyles();

	useEffect( () => {
		setStatus(statusFromCommand);
		dispatch(toggleActionButtons(false));
	}, [statusFromCommand, dispatch]);

	useEffect(() => {
		if (sendStatus === 'success') {
			setTimeout(() => dispatch(setSendStatus('none')), 5000);
		}
	}, [sendStatus, dispatch]);

	return (
		<>
			<Grid item className={classes.root} data-cy="srt-status-control">
				{sendStatus !== 'none' &&
					<CheckCircleIcon
						data-testid="status-check"
						className={classes.checkmark}
						fontSize="default"
						data-cy="srt-status-control-status-check"
					/>
				}
				<FormControl variant="outlined" className={classes.formControl} size="small" data-cy="srt-status-control-form-control">
					<InputLabel data-cy="srt-status-control-status-label">Status</InputLabel>
					<Select
						disabled={disabled}
						onChange={e => setStatus(e.target.value)}
						value={status}
						label="Status"
						className={classes.select}
						data-testid={'status-select'}
						data-cy="srt-status-control-form-control-select"
						SelectDisplayProps={
							{ 'data-cy': 'srt-status-control-form-control-select-display' } as SRTSelectDisplayProps
						}
					>
						<MenuItem value={'Abandoned'} data-cy="srt-status-control-menu-item">Abandoned</MenuItem>
						<MenuItem value={'WinnerCompetitorOneByRetirementOrDefault'} data-cy="srt-status-control-menu-item">
							Winner by default - Home
						</MenuItem>
						<MenuItem value={'WinnerCompetitorTwoByRetirementOrDefault'} data-cy="srt-status-control-menu-item">
							Winner by default - Away
						</MenuItem>
						{additionalStatuses}
					</Select>
				</FormControl>
				<Button
					className={classes.sendButton}
					disabled={status ? disabled : true} /* Disable button until we select status option */
					variant="contained"
					color="primary"
					size="medium"
					disableElevation
					onClick={() => dispatch(showDialog({ dialog: 'ConfirmStatusDialog', props: { status } }))}
					aria-label="status-confirm"
					data-cy="srt-status-control-send-button"
				>
				Send
				</Button>
			</Grid>
		</>
	);
};

export default SRTStatusControl;
