import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';

interface SRTGoalListTableBodyProps {
    className?: string,
    emptyCellClassName?: string
    emptyCellColSpan?: number
}

const SRTGoalListTableBody: React.FC<SRTGoalListTableBodyProps> = props => {
	return (
		<TableBody className={props.className} data-cy="srt-goal-list-table-body">
			{ React.Children.count(props.children) ?
				props.children :
				<TableRow component="tr" data-cy="srt-goal-list-table-row">
					<TableCell colSpan={props.emptyCellColSpan || 5} data-cy="srt-goal-list-table-cell">
						<span className={ props.emptyCellClassName } data-cy="srt-goal-list-table-cell-no-data"> No data</span>
					</TableCell>
				</TableRow>
			}
		</TableBody>
	);
};

export default SRTGoalListTableBody;
