export const maxFrames = 35;
export const maxFrameScore = 200;

export const statusNames = {
	display: {
		1: '1st',
		2: '2nd',
		3: '3rd',
		4: '4th',
		5: '5th',
		6: '6th',
		7: '7th',
		8: '8th',
		9: '9th',
		10: '10th',
		11: '11th',
		12: '12th',
		13: '13th',
		14: '14th',
		15: '15th',
		16: '16th',
		17: '17th',
		18: '18th',
		19: '19th',
		20: '20th',
		21: '21th',
		22: '22th',
		23: '23th',
		24: '24th',
		25: '25th',
		26: '26th',
		27: '27th',
		28: '28th',
		29: '29th',
		30: '30th',
		31: '31th',
		32: '32th',
		33: '33th',
		34: '34th',
		35: '35th',
		111: 'Final Result'
	}
};
