export type Result = [number, number];

export interface ScoreItem {
	dataPoint: string,
	result: Result
}

export enum IceHockeyKeys {
	FIRST_PERIOD = '1',
	SECOND_PERIOD = '2',
	THIRD_PERIOD = '3',
	REGULAR_SCORE = '111',
	FINAL_SCORE = '112'
}

export type IceHockeyScore = {
	[key in IceHockeyKeys] : ScoreItem
}

export interface IceHockeyDataPointsInterface {
	'first_period_score': boolean,
	'second_period_score': boolean,
    'third_period_score': boolean,
	'regular_time_score': boolean,
	'final_score': boolean
}

export type InEditMode = {
	status: boolean,
	columnKey: IceHockeyKeys | null
};
