export const statusNames = {
	msf: {
		1: '1st',
		2: '2nd',
		3: '3rd',
		4: '4th',
		5: '5th',
		111: 'Match Score'
	},
	display: {
		1: '1st set',
		2: '2nd set',
		3: '3rd set',
		4: '4th set',
		5: '5th set',
		111: 'Match Score'
	}
};
