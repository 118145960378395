// TODO request this keys from msf team

export enum HandballMSFNames {
	FirstTeamToScore = 'first_team_to_score',
	FirstTeamToScore3Goals = 'first_team_to_score_3_goals',
	FirstTeamToScore5Goals = 'first_team_to_score_5_goals',
	FirstHalfScore = 'first_half_score',
	SecondHalfScore = 'second_half_score',
	RegularTimeScore = 'regular_time_score',
	LastTeamToScore = 'last_team_to_score'
}

export interface HandballDataPointsInterface {
  /* eslint-disable camelcase */
	first_team_to_score: boolean,
	first_team_to_score_3_goals: boolean,
	first_team_to_score_5_goals: boolean,
	first_half_score: boolean,
	second_half_score: boolean,
	regular_time_score: boolean,
	last_team_to_score: boolean,
  /* eslint-enable camelcase */
}

export type HandballStatusMap = {
	[key in HandballMSFNames] : {
		msf: string,
		display: string,
		dataPoint: HandballMSFNames,
		statusId: number
	}
}

export type HandballScore = Array<{
	dataPoint: HandballMSFNames,
	result: [ number, number ]
}>
