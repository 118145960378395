import React from 'react';
import { BasketballKeys, BasketballScore } from './Basketball.types';

export const initialScoreState: BasketballScore = {
	[BasketballKeys.FirstTeamToScore]: { dataPoint: 'first_team_to_score', result: [-1, -1] },
	[BasketballKeys.FirstQuarterScore]: { dataPoint: '1st_quarter_score', result: [-1, -1] },
	[BasketballKeys.SecondQuarterScore]: { dataPoint: '2nd_quarter_score', result: [-1, -1] },
	[BasketballKeys.FirstHalfScore]: { dataPoint: 'first_half_score', result: [-1, -1] },
	[BasketballKeys.ThirdQuarterScore]: { dataPoint: '3rd_quarter_score', result: [-1, -1] },
	[BasketballKeys.FourthQuarterScore]: { dataPoint: '4th_quarter_score', result: [-1, -1] },
	[BasketballKeys.SecondHalfScore]: { dataPoint: 'second_half_score', result: [-1, -1] },
	[BasketballKeys.FullTimeScore]: { dataPoint: 'full_time_score', result: [-1, -1] },
	[BasketballKeys.FinalScore]: { dataPoint: 'final_score', result: [-1, -1] },
	[BasketballKeys.LastTeamToScore]: { dataPoint: 'last_team_to_score', result: [-1, -1] }
};

export const statusNames = {
	msf: {
		1: 'First To Score',
		2: '1st Quarter',
		3: '2nd Quarter',
		4: '1st Half',
		5: '3rd Quarter',
		6: '4th Quarter',
		7: '2nd Half',
		111: 'FullScore',
		112: 'FinalScore',
		113: 'Last To Score'
	},
	display: {
		1: 'First To Score',
		2: 'First Quarter',
		3: 'Second Quarter',
		4: 'First Half',
		5: 'Third Quarter',
		6: 'Forth Quarter',
		7: 'Second Half',
		111: 'Full Score',
		112: 'Final Score',
		113: 'Last To Score'
	},
	titles: {
		1: 'First To<br/>Score',
		2: '1st<br/>Quarter',
		3: '2nd<br/>Quarter',
		4: '1st<br/>Half',
		5: '3rd<br/>Quarter',
		6: '4th<br/>Quarter',
		7: '2nd<br/>Half',
		111: 'Full<br/>Score',
		112: 'Final<br/>Score',
		113: 'Last To</br>Score'
	}
};

export const tableHeaders = [
	<span>First To<br />Score</span>,
	<span>1st<br/>Quarter</span>,
	<span>2nd<br/>Quarter</span>,
	<span>1st<br/>Half</span>,
	<span>3rd<br/>Quarter</span>,
	<span>4th<br/>Quarter</span>,
	<span>2nd<br/>Half</span>,
	<span>Full<br/>Score</span>,
	<span>Final<br/>Score</span>,
	<span>Last To<br />Score</span>
];

export const dataPointsMapping = {
	firstTeamToScore: {
		index: 1,
		dataPoint: 'first_team_to_score'
	},
	firstQuarterScore: {
		index: 2,
		dataPoint: '1st_quarter_score'
	},
	secondQuarterScore: {
		index: 3,
		dataPoint: '2nd_quarter_score'
	},
	firstHalfScore: {
		index: 4,
		dataPoint: 'first_half_score'
	},
	thirdQuarterScore: {
		index: 5,
		dataPoint: '3rd_quarter_score'
	},
	fourthQuarterScore: {
		index: 6,
		dataPoint: '4th_quarter_score'
	},
	secondHalfScore: {
		index: 7,
		dataPoint: 'second_half_score'
	},
	lastTeamToScore: {
		index: 113,
		dataPoint: 'last_team_to_score'
	}
};
