import { AnyAction } from 'redux';
import { SportActionTpes } from './sport.types';

export const setInitialLoad = (payload): AnyAction => {
	return {
		type: SportActionTpes.setInitialLoad,
		payload
	};
};

export const setSportInfo = (payload): AnyAction => ({
	type: SportActionTpes.setSportInfo,
	payload
});

