
import { VolleyballKeys, VolleyballScore } from './Volleyball.types';

export const initialScoreState: VolleyballScore = {
	[VolleyballKeys.FirstSet]: { dataPoint: 'first_set_score', result: [-1, -1] },
	[VolleyballKeys.SecondSet]: { dataPoint: 'second_set_score', result: [-1, -1] },
	[VolleyballKeys.ThirdSet]: { dataPoint: 'third_set_score', result: [-1, -1] },
	[VolleyballKeys.FourthSet]: { dataPoint: 'fourth_set_score', result: [-1, -1] },
	[VolleyballKeys.FifthSet]: { dataPoint: 'fifth_set_score', result: [-1, -1] },
	[VolleyballKeys.FinalScore]: { dataPoint: 'final_score', result: [-1, -1] }
};

export const statusNames = {
	msf: {
		1: '1st',
		2: '2nd',
		3: '3rd',
		4: '4th',
		5: '5th',
		111: 'Final Score'
	},
	display: {
		1: '1st set',
		2: '2nd set',
		3: '3rd set',
		4: '4th set',
		5: '5th set',
		111: 'Final Score'
	}
};
