import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchData } from 'common/utils/fetch.utils';
import { setConfig } from 'store/config/config.actions';
import { selectConfig } from 'store/config/config.selectors';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import AuthProvider from './AuthProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import App from '../../App';
import { SRTAppDialog } from '../../components';
import { theme } from '../../theme';

const ConfigProvider = () => {
	const dispatch = useDispatch();
	const config = useSelector(selectConfig);

	useEffect(() => {
		const getConfigData = async () => {
			try {
				const config = await fetchData('./config.json');
				dispatch(setConfig(config));
			} catch (e) {
				console.log(e);
			}
		};

		getConfigData();
	}, [dispatch]);

	return Object.keys(config).length > 0 &&

	<Auth0Provider
		domain={config.AUTH0_DOMAIN}
		clientId={config.AUTH0_CLIENT_ID}
		redirectUri={window.location.href}
		audience={'https://' + config.AUTH0_DOMAIN + '/api/v2/'}
	>
		<AuthProvider>
			<Router>
				<ThemeProvider theme={theme}>
					<App />
					<SRTAppDialog />
				</ThemeProvider>
			</Router>
		</AuthProvider>
	</Auth0Provider>
};

export default ConfigProvider;
