export type FutsalResult = [number, number];

export enum FutsalKeys {
	FirstTeamToScore = '0',
	FirstHalfScore = '1',
	SecondHalfScore = '2',
	FullTime = '111'
}

export type FutsalScore = { [key in FutsalKeys]: { dataPoint: string; result: FutsalResult }; };

/* eslint-disable camelcase */
export interface FutsalDataPointsInterface {
	first_team_to_score: boolean,
	first_half_score: boolean,
	second_half_score: boolean,
	regular_time_score: boolean
}
/* eslint-enable camelcase */

export type FutsalInEditMode = {
	status: boolean,
	columnKey: string
};

type FutsalPhaseTypes = 'FirstHalf' | 'SecondHalf' | 'FullScore';

type FutsalTeamTypes = 'Home' | 'Away';

export interface FutsalMatchStateGoalInterface {
	$type: string,
	id: number,
	sequenceId: number,
	phase: FutsalPhaseTypes,
	timeRemainingInPhase: string,
	team: FutsalTeamTypes,
	isOwnGoal: boolean,
	wasScoredFromPenalty: boolean,
	wasScoredFromTenMeterPenalty: boolean,
	isConfirmed: boolean,
	timestampUtc: string
}
