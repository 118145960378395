import { makeStyles } from '@material-ui/core';

export const useSRTStatusControlStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'nowrap'
	},
	formControl: {
		minWidth: 150,
		margin: 0
	},
	select: {
		maxHeight: 36
	},
	checkmark: {
		fontSize: '1.4rem',
		color: theme.palette.primary.main
	},
	sendButton: {
		marginLeft: 2
	}
}));
