import React from 'react';
import { TableCell, Select, MenuItem } from '@material-ui/core';

import { useSRTSelectBoxCellStyles } from './SRTSelectBoxCellStyles';

export interface SRTSelectBoxCellProps {
	id: string;
	value: string;
	sticky?: boolean;
	inEditMode: boolean;
	menuItems: string[],
	handleChange?: (value: any) => void;
	className?: string;
	valueClassName?: string;
	variant?: 'head' | 'body' | 'footer';
	align?: any;
}

const SRTSelectBoxCell = ({
	id,
	value,
	inEditMode,
	menuItems,
	handleChange,
	className = '',
	valueClassName,
	variant,
	align
}: SRTSelectBoxCellProps) => {
	const classes = useSRTSelectBoxCellStyles();

	return (
		<TableCell data-testid={id} id={id} className={className} variant={variant} align={align} data-cy={`${id}-cell`}>
			{inEditMode ? (
				<Select
					className={classes.root}
					id={id}
					value={value}
					onChange={e => {
						const value = e.target.value;
						handleChange(value);}
					}
					inputProps={{
						'data-cy': id
					}}
					SelectDisplayProps={
						{ 'data-cy': `select-display-${id}` } as any
					}
				>
					{menuItems.map(item => (
						<MenuItem key={item + '_option'} value={item} data-cy={`select-menu-item-${item}`}>
							{item}
						</MenuItem>
					))}
				</Select>
			) : (
				<div className={valueClassName} data-cy="srt-select-box-cell-value">{value}</div>
			)}
		</TableCell>
	);
};

export default SRTSelectBoxCell;
