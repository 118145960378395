import { RugbyUnionKeys, RugbyUnionStatusMap } from './RugbyUnion.types';

// TODO check if these keys can be used as strings, since strings serialize better
export const rugbyUnionScoreToStatusMap : RugbyUnionStatusMap = {
	[RugbyUnionKeys.FirstHalf]: { msf: 'FirstHalf', display: '1st Half', dataPoint: 'first_half_score' },
	[RugbyUnionKeys.SecondHalf]: { msf: 'SecondHalf', display: '2nd Half', dataPoint: 'second_half_score' },
	[RugbyUnionKeys.RegularScore]: { msf: 'RegularScore', display: 'Regular Score', dataPoint: 'regular_time_score' }
};

export const rugbyUnionPlaysMap = {
	try: { label: 'Try', score: 5 },
	conversion: { label: 'Conversion', score: 2 },
	dropGoal: { label: 'Drop Goal', score: 3 },
	penalty: { label: 'Penalty', score: 3 }
};

