import React from 'react';

import { FootballScore, FootballKeys } from 'pages/Football/Football.types';

export const initialScoreState: FootballScore = {
	[FootballKeys.FirstHalfScore]: { dataPoint: 'first_half_score', result: [-1, -1] },
	[FootballKeys.SecondHalfScore]: { dataPoint: 'second_half_score', result: [-1, -1] },
	[FootballKeys.FullTime]: { dataPoint: 'full_time_score', result: [-1, -1] }
};

export const tableHeaders = [
	<span>1st<br />Half</span>,
	<span>2nd<br />Half</span>,
	<span>Full<br />Score</span>
];

export const statusNames = {
	msf: {
		1: 'FirstHalf',
		2: 'SecondHalf',
		111: 'FullScore'
	},
	display: {
		1: '1st Half',
		2: '2nd Half',
		111: 'FullScore'
	}
};

export const scoreToStatusMap = {
	1: { msf: 'FirstHalf', display: '1st Half' },
	2: { msf: 'SecondHalf', display: '2nd Half' },
	111: { msf: 'FullScore', display: 'Full Score' }
};
