import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from '@material-ui/core';

import { selectDialogState } from '../../store/dialog/dialog.selectors';
import { hideDialog } from '../../store/dialog/dialog.actions';

import * as dialogRegistry from '../dialogs';

const SRTAppDialog = () => {
	const { isOpen, component, props } = useSelector(selectDialogState);
	const { fullWidth, maxWidth, ...restProps } = props;
	const Element = dialogRegistry[component];
	const dispatch = useDispatch();

	const dismiss = useCallback(() => {
		dispatch(hideDialog());
	}, [dispatch]);

	return (
		<Dialog
			open={isOpen}
			onClose={dismiss}
			fullWidth={fullWidth || true}
			maxWidth={maxWidth || 'sm'}
			data-cy="srt-app-dialog"
		>
			{!!Element && (
				<Element
					{...restProps}
					dismiss={dismiss}
				/>
			)}
		</Dialog>
	);
};

export default SRTAppDialog;
