import { cloneDeep } from 'lodash';
import React from 'react';
import { AussieRulesScore, AussieRulesStatusIds } from './AussieRules.types';

export const initialScoreState: AussieRulesScore = {
	[AussieRulesStatusIds.FIRST_TEAM_TO_SCORE]: {
		dataPoint: 'first_team_to_score',
		result: [-1, -1]
	},
	[AussieRulesStatusIds.FIRST_QUARTER_SCORE]: {
		dataPoint: '1st_quarter_score',
		result: [-1, -1]
	},
	[AussieRulesStatusIds.SECOND_QUARTER_SCORE]: {
		dataPoint: '2nd_quarter_score',
		result: [-1, -1]
	},
	[AussieRulesStatusIds.FIRST_HALF_SCORE]: {
		dataPoint: 'first_half_score',
		result: [-1, -1]
	},
	[AussieRulesStatusIds.THIRD_QUARTER_SCORE]: {
		dataPoint: '3rd_quarter_score',
		result: [-1, -1]
	},
	[AussieRulesStatusIds.FOURTH_QUARTER_SCORE]: {
		dataPoint: '4th_quarter_score',
		result: [-1, -1]
	},
	[AussieRulesStatusIds.SECOND_HALF_SCORE]: {
		dataPoint: 'second_half_score',
		result: [-1, -1]
	},
	[AussieRulesStatusIds.REGULAR_SCORE]: {
		dataPoint: 'regular_time_score',
		result: [-1, -1]
	}
};

export const tableHeaders = [
	<span>First To<br />Score</span>,
	<span>1st<br />Quarter</span>,
	<span>2nd<br />Quarter</span>,
	<span>1st<br />Half</span>,
	<span>3rd<br />Quarter</span>,
	<span>4th<br />Quarter</span>,
	<span>2nd<br />Half</span>,
	<span data-testid={'Full Score'}>Full<br />Score</span>
];

export const getTeamsFromFixture = fixturecompetitors => {
	return [
		fixturecompetitors[0] ? fixturecompetitors[0]?.competitor?.name : 'No Team 1 Info',
		fixturecompetitors[1] ? fixturecompetitors[1]?.competitor?.name : 'No Team 2 Info'
	];
};

export const calculateFullScore = (score: AussieRulesScore): number[] => {
	const filteredQuartersScore = Object.values(score).filter(
		p => p.dataPoint !== 'regular_time_score' && p.dataPoint.includes('quarter')
	);

	const newFullScore = [0, 0];
	for (const dataPoint of filteredQuartersScore) {
		const result = dataPoint.result;
		if (result[0] !== -1) {
			newFullScore[0] += result[0];
		}
		if (result[1] !== -1) {
			newFullScore[1] += result[1];
		}
	}

	return newFullScore;
};

export const calculateFullScoreFromHalfs = (score: AussieRulesScore): number[] => {
	const filteredHalfsScore = Object.values(score).filter(
		p => p.dataPoint !== 'regular_time_score' && p.dataPoint.includes('half')
	);

	const newFullScore = [0, 0];
	for (const dataPoint of filteredHalfsScore) {
		const result = dataPoint.result;
		if (result[0] !== -1) {
			newFullScore[0] += result[0];
		}
		if (result[1] !== -1) {
			newFullScore[1] += result[1];
		}
	}

	return newFullScore;
};

export const isFullTimeScoreDisabled = dataPoints => {
	return !(dataPoints['regular_time_score'] && !dataPoints['1st_quarter_score'] && !dataPoints['2nd_quarter_score'] &&
		!dataPoints['3rd_quarter_score'] && !dataPoints['4th_quarter_score'] && !dataPoints['first_half_score'] &&
		!dataPoints['second_half_score']);
};

export const isFirstHalfScoreDisabled = dataPoints => {
	return !(dataPoints['first_half_score'] && !dataPoints['1st_quarter_score'] && !dataPoints['2nd_quarter_score'] &&
		!dataPoints['3rd_quarter_score'] && !dataPoints['4th_quarter_score']);
};

export const isSecondHalfScoreDisabled = (dataPoints, score: AussieRulesScore) => {
	return !(dataPoints['second_half_score'] && !dataPoints['1st_quarter_score'] && !dataPoints['2nd_quarter_score'] &&
		!dataPoints['3rd_quarter_score'] && !dataPoints['4th_quarter_score'] &&
		score[AussieRulesStatusIds.FIRST_HALF_SCORE].result[0] >= 0 && score[AussieRulesStatusIds.FIRST_HALF_SCORE].result[1] >= 0);
};

export const isDisabledEdit = (dataPoint: string, dataPoints, score): boolean => {
	switch (dataPoint) {
		case 'first_team_to_score':
		case '1st_quarter_score':
			return !dataPoints[dataPoint];
		case '2nd_quarter_score':
			return !(dataPoints['2nd_quarter_score'] &&
			score[AussieRulesStatusIds.FIRST_QUARTER_SCORE].result.every( i => i >= 0));
		case '3rd_quarter_score':
			return !(dataPoints['3rd_quarter_score'] &&
			score[AussieRulesStatusIds.SECOND_QUARTER_SCORE].result.every( i => i >= 0));
		case '4th_quarter_score':
			return !(dataPoints['4th_quarter_score'] &&
			score[AussieRulesStatusIds.THIRD_QUARTER_SCORE].result.every( i => i >= 0));
		case 'regular_time_score':
			return isFullTimeScoreDisabled(dataPoints);
		case 'first_half_score':
			return isFirstHalfScoreDisabled(dataPoints);
		case 'second_half_score':
			return isSecondHalfScoreDisabled(dataPoints, score);
		default:
			return true;
	}
};

export const calculateHalfScore = (score: AussieRulesScore) => {
	// Calculate first half result
	score[AussieRulesStatusIds.FIRST_HALF_SCORE].result[0] =
		(score[AussieRulesStatusIds.FIRST_QUARTER_SCORE].result[0] === -1 ?
			-1 : score[AussieRulesStatusIds.FIRST_QUARTER_SCORE].result[0]) +
		(score[AussieRulesStatusIds.SECOND_QUARTER_SCORE].result[0] === -1 ?
			0 : score[AussieRulesStatusIds.SECOND_QUARTER_SCORE].result[0]);
	score[AussieRulesStatusIds.FIRST_HALF_SCORE].result[1] =
		(score[AussieRulesStatusIds.FIRST_QUARTER_SCORE].result[1] === -1 ?
			-1 : score[AussieRulesStatusIds.FIRST_QUARTER_SCORE].result[1]) +
		(score[AussieRulesStatusIds.SECOND_QUARTER_SCORE].result[1] === -1 ?
			0 : score[AussieRulesStatusIds.SECOND_QUARTER_SCORE].result[1]);
	// Calculate second half result
	score[AussieRulesStatusIds.SECOND_HALF_SCORE].result[0] =
		(score[AussieRulesStatusIds.THIRD_QUARTER_SCORE].result[0] === -1 ?
			-1 : score[AussieRulesStatusIds.THIRD_QUARTER_SCORE].result[0]) +
		(score[AussieRulesStatusIds.FOURTH_QUARTER_SCORE].result[0] === -1 ?
			0 : score[AussieRulesStatusIds.FOURTH_QUARTER_SCORE].result[0]);
	score[AussieRulesStatusIds.SECOND_HALF_SCORE].result[1] =
		(score[AussieRulesStatusIds.THIRD_QUARTER_SCORE].result[1] === -1 ?
			-1 : score[AussieRulesStatusIds.THIRD_QUARTER_SCORE].result[1]) +
		(score[AussieRulesStatusIds.FOURTH_QUARTER_SCORE].result[1] === -1 ?
			0 : score[AussieRulesStatusIds.FOURTH_QUARTER_SCORE].result[1]);
	return score;
};

export const statusNames = {
	msf: {
		1: '1st Quarter',
		2: '2nd Quarter',
		3: '1st Half',
		4: '3rd Quarter',
		5: '4th Quarter',
		6: '2nd Half',
		111: 'FullScore'
	},
	display: {
		0: 'First To Score',
		1: '1st Quarter',
		2: '2nd Quarter',
		3: '1st Half',
		4: '3rd Quarter',
		5: '4th Quarter',
		6: '2nd Half',
		111: 'Full Score'
	},
	titles: {
		0: 'First To<br/>Score',
		1: '1st<br/>Quarter',
		2: '2nd<br/>Quarter',
		3: '1st<br/>Half',
		4: '3rd<br/>Quarter',
		5: '4th<br/>Quarter',
		6: '2nd<br/>Half',
		111: 'Full<br/>Score'
	}
};

// Const dataPointsMapping = {
// 	firstTeamToScore: {
// 		statusId: AussieRulesStatusIds.FIRST_TEAM_TO_SCORE,
// 		dataPoint: 'first_team_to_score'
// 	},
// 	firstQuarterScore: {
// 		statusId: AussieRulesStatusIds.FIRST_QUARTER_SCORE,
// 		dataPoint: '1st_quarter_score'
// 	},
// 	secondQuarterScore: {
// 		statusId: AussieRulesStatusIds.SECOND_QUARTER_SCORE,
// 		dataPoint: '2nd_quarter_score'
// 	},
// 	firstHalfScore: {
// 		statusId: AussieRulesStatusIds.FIRST_HALF_SCORE,
// 		dataPoint: 'first_half_score'
// 	},
// 	thirdQuarterScore: {
// 		statusId: AussieRulesStatusIds.THIRD_QUARTER_SCORE,
// 		dataPoint: '3rd_quarter_score'
// 	},
// 	fourthQuarterScore: {
// 		statusId: AussieRulesStatusIds.FOURTH_QUARTER_SCORE,
// 		dataPoint: '4th_quarter_score'
// 	},
// 	secondHalfScore: {
// 		statusId: AussieRulesStatusIds.SECOND_HALF_SCORE,
// 		dataPoint: 'second_half_score'
// 	}
// };

/**
 * @todo Commented until we get match state data
 * @param score @@
 * @param matchState
 * @param dataPoints
 * @returns AussieRulesScore parsed from match state
 */
export const populateDataFromMatchState = (score: AussieRulesScore/*, matchState, dataPoints*/): AussieRulesScore => {
	const newScore = cloneDeep(score);

	// Object.entries(matchState.gameSummary.scores)
	// 	.filter(([key]) => dataPoints[dataPointsMapping[key]?.dataPoint])
	// 	.forEach(([key, value]: any) => {
	// 		const statusId = dataPointsMapping[key].statusId;
	// 		if (key === 'firstTeamToScore') {
	// 			newScore[statusId].result = [value.toLowerCase() === 'home' ? 1 : 0, value.toLowerCase() === 'away' ? 1 : 0];
	// 		} else {
	// 			newScore[statusId].result = [value.home, value.away];
	// 		}
	// 	});

	// if (dataPoints['regular_time_score'] && !dataPoints['1st_quarter_score'] && !dataPoints['2nd_quarter_score'] &&
	// 	!dataPoints['3rd_quarter_score'] && !dataPoints['4th_quarter_score'] && !dataPoints['first_half_score'] &&
	// 	!dataPoints['second_half_score'] && matchState.gameSummary.scores.regulationTimeFinalScore) {
	// 	const finalScore = matchState.gameSummary.scores.regulationTimeFinalScore;
	// 	newScore[AussieRulesStatusIds.REGULAR_SCORE].result = [finalScore.home, finalScore.away];
	// }

	// if (matchState.gameSummary.scores.regulationTimeFinalScore) {
	// 	const finalScore = matchState.gameSummary.scores.regulationTimeFinalScore;
	// 	newScore[AussieRulesStatusIds.REGULAR_SCORE].result = [finalScore.home, finalScore.away];

	// 	if (matchState.gameSummary.scores.overtimeScore &&
	// 		Object.keys(matchState.gameSummary.scores.overtimeScore).length > 0) {
	// 		const overtimeScore = matchState.gameSummary.scores.overtimeScore;
	// 		newScore[AussieRulesStatusIds.FULL_TIME_SCORE].result[0] += overtimeScore.home;
	// 		newScore[AussieRulesStatusIds.FULL_TIME_SCORE].result[1] += overtimeScore.away;
	// 	}
	// }

	return newScore;
};
