import { makeStyles } from '@material-ui/core';

export const useSRTEventInfoStateStyles = makeStyles(theme => ({
	paper: {
		padding: theme.spacing(1),
		color: theme.palette.text.secondary,
		borderRadius: 0,
		boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%)',
		background: 'transparent',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	title: {
		minWidth: '9rem'
	},
	value: {
		[theme.breakpoints.down('xs')]: {
			marginLeft: 'auto'
		}
	}
}));
