export const removeLowerCaseProps = object => {
	const out = {};
	if (typeof object === 'object') {
		for (let key in object) {
			if (Object.prototype.hasOwnProperty.call(object, key)) {
				const element = object[key];
				key = key.charAt(0).toLocaleLowerCase() + key.slice(1);
				if (Array.isArray(element)) {
					out[key] = element.map(el => removeLowerCaseProps(el));
				} else {
					out[key] = removeLowerCaseProps(element);
				}

			}
		}
		return out;
	} else {
		return object;
	}
};

export const fetchData = async (url: string) => {
	try {
		const data = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			}
		});
		return await data.json();
	} catch (e) {
		return null;
	}
};
