import React from 'react';
import { IceHockeyScore, IceHockeyKeys } from './IceHockey.types';

export const tableHeaders = [
	<span>1st</span>,
	<span>2nd</span>,
	<span>3rd</span>,
	<span>Regular Time</span>,
	<span>Final Score</span>
];

export const statusNames = {
	msf: {
		[IceHockeyKeys.FIRST_PERIOD]: 'FirstPeriod',
		[IceHockeyKeys.SECOND_PERIOD]: 'SecondPeriod',
		[IceHockeyKeys.THIRD_PERIOD]: 'ThirdPeriod',
		[IceHockeyKeys.REGULAR_SCORE]: 'RegularTimeScore',
		[IceHockeyKeys.FINAL_SCORE]: 'FinalScore'
	},
	display: {
		[IceHockeyKeys.FIRST_PERIOD]: '1st Period',
		[IceHockeyKeys.SECOND_PERIOD]: '2nd Period',
		[IceHockeyKeys.THIRD_PERIOD]: '3rd Period',
		[IceHockeyKeys.REGULAR_SCORE]: 'Regular Time',
		[IceHockeyKeys.FINAL_SCORE]: 'Final Score'
	}
};

export const initialScoreState : IceHockeyScore = {
	[IceHockeyKeys.FIRST_PERIOD]: {
		dataPoint: 'first_period_score',
		result: [-1, -1]
	},
	[IceHockeyKeys.SECOND_PERIOD]: {
		dataPoint: 'second_period_score',
		result: [-1, -1]
	},
	[IceHockeyKeys.THIRD_PERIOD]: {
		dataPoint: 'third_period_score',
		result: [-1, -1]
	},
	[IceHockeyKeys.REGULAR_SCORE]: {
		dataPoint: 'regular_time_score',
		result: [-1, -1]
	},
	[IceHockeyKeys.FINAL_SCORE]: {
		dataPoint: 'final_score',
		result: [-1, -1]
	}
};
