/* eslint-disable max-lines */
import cloneDeep from 'lodash.clonedeep';

import {
	BaseballDataPointsInterface,
	BaseballInningScoreInterface,
	BaseballCommandValueKey,
	BaseballCommandKey,
	BaseballDataPointsNames,
	BaseballStatusID
} from './Baseball.types';

export const calculateRegularTimeScore = (score: BaseballInningScoreInterface[]): [number, number] => {
	const regularinnings = score.filter(inning => inning.dataPoint !== BaseballDataPointsNames.regularTimeScore
	&& inning.dataPoint !== BaseballDataPointsNames.finalScore
	&& parseInt(inning.dataPoint.split('_')[1]) < 10);
	const regularTimeScore: [number, number] = [0, 0];
	regularinnings.forEach(inning => {
		regularTimeScore[0] = inning.result[0] === -1 ? regularTimeScore[0] : regularTimeScore[0] + inning.result[0];
		regularTimeScore[1] = inning.result[1] === -1 ? regularTimeScore[1] : regularTimeScore[1] + inning.result[1];
	});

	return regularTimeScore;
};

export const calculateFinalScore = (score: BaseballInningScoreInterface[]): [number, number] => {
	const extrainnings = score.filter(inning => inning.dataPoint !== BaseballDataPointsNames.regularTimeScore
	&& inning.dataPoint !== BaseballDataPointsNames.finalScore
	&& parseInt(inning.dataPoint.split('_')[1]) > 9);
	const regularTimeScore = score.filter(inning => inning.dataPoint === BaseballDataPointsNames.regularTimeScore)[0];
	// Const finalScore: [number, number] = regularTimeScore.result.slice(0);
	const finalScore: [number, number] = [...regularTimeScore.result];
	extrainnings.forEach(inning => {
		finalScore[0] = inning.result[0] === -1 ? finalScore[0] : finalScore[0] + inning.result[0];
		finalScore[1] = inning.result[1] === -1 ? finalScore[1] : finalScore[1] + inning.result[1];
	});

	return finalScore;
};

const getOrdinal = (n: number): string => {
	const s = ['th', 'st', 'nd', 'rd'];
	const v = n%100;
	return n + (s[(v-20)%10] || s[v] || s[0]);
};

export const buildHeaders = (score: BaseballInningScoreInterface[]): string[] => {
	const result = score.map((dataPoint, i) => {
		if (i < 9) {return getOrdinal(i + 1);}
		if (dataPoint.dataPoint === BaseballDataPointsNames.regularTimeScore) {return 'Regular Time Score';}
		if (dataPoint.dataPoint === BaseballDataPointsNames.finalScore) {return 'Final Score';}
		return getOrdinal(i);
	});

	return result;
};

export const getBaseballStatusName = (statusId: number): string => {
	if (statusId === 111) { return 'Regular Time Score'; }
	if (statusId === 112) { return 'Final Score'; }
	const inningSuffixes = ['th', 'st', 'nd', 'rd'];
	const inningSuffixIndex = statusId >= 4 ? 0 : statusId;
	return `${statusId}${inningSuffixes[inningSuffixIndex]}`;
};

export const isAnyInningEditable = (dataPoints: BaseballDataPointsInterface) : boolean => Object.keys(dataPoints).filter(key =>
	key !== BaseballDataPointsNames.regularTimeScore
	&& key !== BaseballDataPointsNames.extraInnings
	&& key !== BaseballDataPointsNames.finalScore)
	.some(key => dataPoints[key]);

export const isExtraInningsNeeded = (score: BaseballInningScoreInterface[], dataPoints: BaseballDataPointsInterface): boolean => {
	const isExtraInningScore = score.length > 11;
	const finalScore = score.filter(inning => inning.dataPoint === BaseballDataPointsNames.finalScore)[0];
	if (!finalScore) {return false;}
	if (finalScore.result[0] === -1 || finalScore.result[1] === -1) {return false;}
	if (!isExtraInningScore) {
		const isFullScore = isAnyInningEditable(dataPoints) ? score[8].result[0] !== -1 && score[8].result[1] !== -1 : true;
		return isFullScore && finalScore.result[0] === finalScore.result[1];
	}
	return score[score.length - 2].result[0] !== -1
	&& score[score.length - 2].result[1] !== -1
	&& finalScore.result[0] === finalScore.result[1];
};

export const getLastInning = (score: BaseballInningScoreInterface[], dataPoints: BaseballDataPointsInterface): number => {
	let lastInning = !isAnyInningEditable(dataPoints) ? 9 : 0;
	const innings = score.filter(inning => {
		let result = inning.dataPoint !== BaseballDataPointsNames.finalScore
		&& inning.dataPoint !== BaseballDataPointsNames.regularTimeScore;
		if (!isAnyInningEditable(dataPoints)){
			result = result && parseInt(inning.dataPoint.split('_')[1]) > 9;
		}
		return result;
	});

	Array.isArray(innings) && innings.forEach(inning => {
		if (inning.result[0] !== -1 && inning.result[1] !== -1)
		{lastInning = parseInt(inning.dataPoint.split('_')[1]);}
	});
	return lastInning;
};

export const getNewDataPoint = (score: BaseballInningScoreInterface[]): string => {
	let offset = 2;
	if (score[score.length - offset].dataPoint === BaseballDataPointsNames.regularTimeScore)
	{offset++;}
	const lastDataPoint = score[score.length - offset].dataPoint;
	return `inning_${parseInt(lastDataPoint.split('_')[1]) + 1}`;
};

export const getTeamsFromFixture = (fixturecompetitors: any) : [string, string] => {
	return [
		fixturecompetitors[0] ? fixturecompetitors[0]?.competitor?.name : 'No Team 1 Info',
		fixturecompetitors[1] ? fixturecompetitors[1]?.competitor?.name : 'No Team 2 Info'
	];
};

export const isDisabledEdit = (score: BaseballInningScoreInterface[], dataPoints: BaseballDataPointsInterface, dataPoint: string)
: boolean => {
	const lastInning = getLastInning(score, dataPoints);

	let isEditable = false;
	switch (dataPoint) {
		case BaseballDataPointsNames.regularTimeScore:
			isEditable = !isAnyInningEditable(dataPoints);
			break;
		case BaseballDataPointsNames.finalScore:
			isEditable = !isAnyInningEditable(dataPoints) && !dataPoints.extra_innings;
			break;
		default:
			if (parseInt(dataPoint.split('_')[1]) > 9) {
				isEditable = dataPoints.extra_innings && parseInt(dataPoint.split('_')[1]) - lastInning < 2;
			} else
			{isEditable = dataPoints[dataPoint] && parseInt(dataPoint.split('_')[1]) - lastInning < 2;}
	}

	return !isEditable;
};

export const checkScore = (score: BaseballInningScoreInterface[], dataPoints: BaseballDataPointsInterface, dataPoint: string)
: {result: boolean, errorMessage: string} => {
	const partialScore = score.filter(value => value.dataPoint === dataPoint)[0];
	const regularTimeScore = score.filter(value => value.dataPoint === BaseballDataPointsNames.regularTimeScore)[0];
	const finalScore = score.filter(value => value.dataPoint === BaseballDataPointsNames.finalScore)[0];
	if (!partialScore || !regularTimeScore || !finalScore) {return {
		result: false,
		errorMessage: 'Something go wrong!'
	};}

	const isExtraInningScore = score.length > 11;
	const response = {
		result: true,
		errorMessage: ''
	};

	switch (dataPoint) {
		case BaseballDataPointsNames.regularTimeScore:
			if (!dataPoints.extra_innings){
				response.result = (finalScore.result[0] !== -1 ? partialScore.result[0] <= finalScore.result[0] : true)
				&& (finalScore.result[1] !== -1 ? partialScore.result[1] <= finalScore.result[1] : true);
				response.errorMessage = 'The final score must be equal or greater than the regular time score!';
			} else {
				response.result = isExtraInningScore ? partialScore.result[0] === partialScore.result[1] : true;
				response.errorMessage = 'With such a result, there should have been no extra innings!';
			}
			break;
		case BaseballDataPointsNames.finalScore:
			response.result = regularTimeScore.result[0] <= partialScore.result[0]
			&& regularTimeScore.result[1] <= partialScore.result[1];
			response.errorMessage = 'The final score must be equal or greater than the regular time score!';
			break;
		default:
			if (parseInt(dataPoint.split('_')[1]) < 10){
				const regularTimeScore = calculateRegularTimeScore(score);
				response.result = isExtraInningScore ? regularTimeScore[0] === regularTimeScore[1] : true;
			}
			else {
				response.result = score.length - parseInt(dataPoint.split('_')[1]) > 2
					? partialScore.result[0] === partialScore.result[1] : true;
			}
			response.errorMessage = 'With such a result, there should have been no extra innings!';
			break;
	}

	return response;
};

export const getScoreFromMatchState = (score: BaseballInningScoreInterface[], matchState: any) : BaseballInningScoreInterface[] => {
	const newScore: BaseballInningScoreInterface[] = cloneDeep(score);
	const inningsValues = matchState?.innings?.$values;
	const totalRuns = matchState?.totalRuns;
	if (inningsValues?.length > 0) {
		newScore.pop();
		for (const inning of inningsValues) {
			if (inning.inningNumber > 9) {
				newScore.push({
					dataPoint: 'inning_' + inning.inningNumber,
					result: [-1, -1]
				});
			}
			const inningScoreIndex = newScore.findIndex(dataPoint => dataPoint.dataPoint === 'inning_' + inning.inningNumber);
			newScore[inningScoreIndex].result[0] = inning.runs.home;
			newScore[inningScoreIndex].result[1] = inning.runs.away;
		}
		newScore.push({
			dataPoint: BaseballDataPointsNames.finalScore,
			result: [0, 0]
		});
	}

	const finalScoreIndex = newScore.findIndex(dataPoint =>
		dataPoint.dataPoint === BaseballDataPointsNames.finalScore
	);

	const regularTimeScoreIndex = newScore.findIndex(dataPoint =>
		dataPoint.dataPoint === BaseballDataPointsNames.regularTimeScore
	);

	newScore[finalScoreIndex].result[0] = totalRuns.home;
	newScore[finalScoreIndex].result[1] = totalRuns.away;

	const regularTimeScore = calculateRegularTimeScore(newScore);

	newScore[regularTimeScoreIndex].result[0] = regularTimeScore[0];
	newScore[regularTimeScoreIndex].result[1] = regularTimeScore[1];

	return newScore;
};

/* eslint-disable camelcase */
export const getSendingCommandParams = (score: BaseballInningScoreInterface[], columnId: string, dataPointIndex: number)
: { key: string, status_id: string, value: any } => {
	let status_id: string;
	let key: BaseballCommandKey;
	let valueKey: BaseballCommandValueKey;
	switch (columnId) {
		case BaseballCommandValueKey.regularTimeScore:
			key = BaseballCommandKey.regularTimeScore;
			status_id = BaseballStatusID.regularTimeScore;
			valueKey = BaseballCommandValueKey.regularTimeScore;
			break;
		case BaseballCommandValueKey.finalScore:
			key = BaseballCommandKey.finalScore;
			status_id = BaseballStatusID.finalScore;
			valueKey = BaseballCommandValueKey.finalScore;
			break;
		default:
			key = BaseballCommandKey.gameScore;
			status_id = score[dataPointIndex].dataPoint.split('_')[1];
			valueKey = BaseballCommandValueKey.gameScore;
			break;
	}

	const value = {
		[valueKey]: score[dataPointIndex].result.slice(0)
	};

	return { key, status_id, value };
};
/* eslint-enable camelcase */

export const getScoreFromCommandLog = (score: BaseballInningScoreInterface[], commands: any,
	dataPoints: BaseballDataPointsInterface, mode: ProcessLogsModes) : BaseballInningScoreInterface[] => {
	const newScore: BaseballInningScoreInterface[] = cloneDeep(score);
	Array.isArray(commands) && commands.forEach(command => {
		if (mode === 'commands') {
			switch (command.value.key) {
				case BaseballCommandKey.gameScore:
					if (Array.isArray(command.value?.value?.game_score)) {
						const inningNumber = parseInt(command.value.status_id);
						const inningScore = command.value.value.game_score.slice(0);
						const isInningInScore = newScore.some(dataPoint => inningNumber === parseInt(dataPoint.dataPoint.split('_')[1]));
						if (!isInningInScore){
							newScore.pop();
							newScore.push({
								dataPoint: 'inning_' + inningNumber,
								result: [-1, -1]
							});
							newScore.push({
								dataPoint: BaseballDataPointsNames.finalScore,
								result: [0, 0]
							});
						}
						const inningScoreIndex = newScore.findIndex(dataPoint => dataPoint.dataPoint === 'inning_' + inningNumber);
						newScore[inningScoreIndex].result = inningScore;

						if (inningNumber < 10) {
							const regularTimeScore = calculateRegularTimeScore(newScore);
							const regularTimeScoreIndex = newScore.findIndex(dataPoint =>
								dataPoint.dataPoint === BaseballDataPointsNames.regularTimeScore
							);
							newScore[regularTimeScoreIndex].result = regularTimeScore;
						}

						const finalScore = calculateFinalScore(newScore);
						const finalScoreIndex = newScore.findIndex(dataPoint =>
							dataPoint.dataPoint === BaseballDataPointsNames.finalScore
						);
						newScore[finalScoreIndex].result = finalScore;
					}
					break;
				case BaseballCommandKey.regularTimeScore:
					if (Array.isArray(command.value?.value?.regular_time_score)) {
						const regularTimeScoreIndex = newScore.findIndex(dataPoint =>
							dataPoint.dataPoint === BaseballDataPointsNames.regularTimeScore
						);
						newScore[regularTimeScoreIndex].result = command.value.value.regular_time_score.slice(0);

						if (dataPoints.extra_innings && dataPoints.final_score) {
							const finalScore = calculateFinalScore(newScore);
							const finalScoreIndex = newScore.findIndex(dataPoint =>
								dataPoint.dataPoint === BaseballDataPointsNames.finalScore
							);
							newScore[finalScoreIndex].result = finalScore;
						}
					}
					break;
				case BaseballCommandKey.finalScore:
					if (Array.isArray(command.value?.value?.final_score)) {
						const finalScoreIndex = newScore.findIndex(dataPoint =>
							dataPoint.dataPoint === BaseballDataPointsNames.finalScore
						);
						newScore[finalScoreIndex].result = command.value.value.final_score.slice(0);
					}
					break;
				default:
					break;
			}
		}
	});

	if (isExtraInningsNeeded(newScore, dataPoints) && dataPoints.extra_innings){
		const newDataPoint = getNewDataPoint(newScore);
		const finalScore = newScore.pop();
		newScore.push({
			dataPoint: newDataPoint,
			result: [-1, -1]
		});
		newScore.push({
			dataPoint: BaseballDataPointsNames.finalScore,
			result: [...finalScore.result]
		});
	}

	return newScore;
};
