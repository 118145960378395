import { makeStyles } from '@material-ui/core';

export const useSRTEventControlsStyles = makeStyles(theme => ({
	container: {
		marginTop: 10,
		marginBottom: 10
	},
	logGrid: {
		display: 'flex',
		justifyContent: 'flex-start',
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'center'
		}
	},
	controlGrid: {
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			order: 2,
			marginTop: '1rem'
		}
	},
	statusGrid: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			marginTop: '1rem',
			justifyContent: 'center'
		}
	},
	resetButton: {
		height: 36,
		marginRight: 2,
		marginLeft: 3
	},
	darkSecondaryColorButton: {
		color: theme.palette.secondary.dark
	},
	hiddenButton: {
		display: 'none'
	},
	btnMargin: {
		margin: '0px 2px'
	}
}));
