import React from 'react';

import { MotorsportKeys, StatusNames } from './Motorsport.types';

export const initialTableState = {
	[MotorsportKeys.QualificationResult]: { dataPoint: 'qualification_result', value: '' },
	[MotorsportKeys.FastestLap]: { dataPoint: 'fastest_lap', value: '' },
	[MotorsportKeys.FirstLapLeader]: { dataPoint: 'first_lap_leader', value: '' },
	[MotorsportKeys.RaceResult]: { dataPoint: 'race_result', value: '' }
};

export const tableHeaders = [
	<span>Qualification<br />Result</span>,
	<span>Fastest<br/>Lap</span>,
	<span>First Lap<br/>Leader</span>,
	<span>Race<br/>Result</span>
];

export const statusNames: StatusNames = {
	display: {
		[MotorsportKeys.RacePositions]: 'Race Positions',
		[MotorsportKeys.QualificationResult]: 'Qualification Result',
		[MotorsportKeys.FastestLap]: 'Fastest Lap',
		[MotorsportKeys.FirstLapLeader]: 'First Lap Leader',
		[MotorsportKeys.RaceResult]: 'Race Result'
	}
};

export const raceTeams = [
	'Alfa Romeo',
	'AlphaTauri',
	'Alpine',
	'Aston Martin',
	'Ferrari',
	'Haas',
	'McLaren',
	'Mercedes',
	'Red Bull',
	'Williams'
];

