import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { useIconButtonStyles } from './SRTIconButtonStyles';

interface SRTIconButtonProps {
	className?: string;
	tooltipTitle?: string | JSX.Element;
	disabled?: boolean;
	icon: JSX.Element;
	clickHandler: () => void;
	size?: SRTIconButtonSizes;
	ariaLabel?: string,
	dataCy?: string
}

const SRTIconButton = ({
	className = '',
	tooltipTitle = '',
	disabled,
	icon,
	clickHandler,
	size = 'large',
	ariaLabel,
	dataCy
}: SRTIconButtonProps) => {
	const { iconBtn, tooltip } = useIconButtonStyles();

	return (
		<Tooltip title={tooltipTitle} placement="bottom" classes={{ tooltip }} data-cy="srt-icon-button-tooltip">
			<span>
				<Button
					aria-label={ariaLabel}
					size={size}
					variant="contained"
					color="default"
					classes={{ root: `${iconBtn} ${className}` }}
					onClick={clickHandler}
					disableElevation
					startIcon={icon}
					disabled={disabled}
					disableRipple={true}
					data-cy={dataCy}
				>
					{''}
				</Button>
			</span>
		</Tooltip>
	);
};

export default SRTIconButton;
