import React from 'react';

import { FutsalKeys, FutsalScore } from './Futsal.types';

export const initialScoreState: FutsalScore = {
	[FutsalKeys.FirstTeamToScore]: { dataPoint: 'first_team_to_score', result: [-1, -1] },
	[FutsalKeys.FirstHalfScore]: { dataPoint: 'first_half_score', result: [-1, -1] },
	[FutsalKeys.SecondHalfScore]: { dataPoint: 'second_half_score', result: [-1, -1] },
	[FutsalKeys.FullTime]: { dataPoint: 'regular_time_score', result: [-1, -1] }
};

export const tableHeaders = [
	<span>First To<br />Score</span>,
	<span>1st<br />Half</span>,
	<span>2nd<br />Half</span>,
	<span>Full<br />Score</span>
];

type FutsalPhaseToStatusIdItem = {
	[key: string]: string
}

export const statusNames = {
	display: {
		0: 'First to score',
		1: '1st Half',
		2: '2nd Half',
		111: 'Full Score'
	},
	msf: {
		0: 'FirstToScore',
		1: 'FirstHalf',
		2: 'SecondHalf',
		111: 'FullScore'
	}
};

export const phaseToScoreMap: FutsalPhaseToStatusIdItem = {
	FirstTeamToScore: FutsalKeys.FirstTeamToScore,
	FirstHalf: FutsalKeys.FirstHalfScore,
	SecondHalf: FutsalKeys.SecondHalfScore,
	FullScore: FutsalKeys.FullTime
};

