import { AnyAction } from 'redux';
import { ControlTakenPayload, UIActionTypes } from './ui.types';

export const toggleLoadingOverlay = (disabled: boolean): AnyAction => ({
	type: UIActionTypes.toggleLoadingOverlay,
	payload: disabled
});

export const toggleActionButtons = (disabled: boolean): AnyAction => ({
	type: UIActionTypes.toggleActionButtons,
	payload: disabled
});

export const setControlTaken = (payload: ControlTakenPayload): AnyAction => ({
	type: UIActionTypes.setControlTaken,
	payload
});

export const setSendStatus = (payload: string): AnyAction => ({
	type: UIActionTypes.setSendStatus,
	payload
});
