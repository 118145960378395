import { makeStyles } from '@material-ui/core';

export const useSRTInputCellStyles = makeStyles(theme => ({
	root: {
		width: '90%',
		fontSize: '1rem',
		justifyContent: 'center',
		backgroundColor: theme.palette.primary.contrastText
	},
	input: {
		textAlign: 'center'
	}
}));
