import React from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@material-ui/core';

import { sendData } from 'common/utils/socket.utils';
import { sportIds } from 'common/utils/constants';
import { useSearchParams } from 'common/hooks/useSearchParams';
import { selectAuthState } from 'store/auth/auth.selectors';

interface ConfirmGetControlDialogProps {
	controlledBy: string;
	dismiss: () => void;
}

const ConfirmGetControlDialog = ({ controlledBy, dismiss }: ConfirmGetControlDialogProps) => {
	const { username } = useSelector(selectAuthState);

	const { eventId, sport } = useSearchParams();

	const handleGetControll = () => {
		sendData({
			type: 'setting',
			user: username,
			page: 'resulting',
			value: {
				key: 'takeControl',
				'event_id': eventId,
				'sport_id': sportIds[sport],
				UTCtimestamp: new Date().toISOString()
			}
		});
		dismiss();
	};

	return (
		<>
			<DialogTitle data-cy="confim-get-control-dialog-title">Are you sure you want to get the control?</DialogTitle>
			<DialogContent>
				<DialogContentText data-cy="confim-get-control-dialog-text">
					Someone else already has the control. Do you want to take the control from {controlledBy}?
				</DialogContentText>
			</DialogContent>
			<DialogActions data-cy="confim-get-control-dialog-actions">
				<Button
					aria-label="confirm"
					onClick={handleGetControll}
					variant="contained"
					color="primary"
					disableElevation
					data-cy="confim-get-control-dialog-yes"
				>
					Yes
				</Button>
				<Button onClick={dismiss} variant="outlined" color="primary" disableElevation data-cy="confim-get-control-dialog-no">
					No
				</Button>
			</DialogActions>
		</>
	);
};

export default ConfirmGetControlDialog;
