import { HandballMSFNames, HandballScore, HandballStatusMap } from './Handball.types';

export const HandballUnionScoreToStatusMap : HandballStatusMap = {
	[HandballMSFNames.FirstTeamToScore]: {
		msf: 'firstTeamToScore',
		display: 'First Team to Score',
		dataPoint: HandballMSFNames.FirstTeamToScore,
		statusId: 1
	},
	[HandballMSFNames.FirstTeamToScore3Goals]: {
		msf: 'firstTeamToScore3',
		display: 'First Team to Score 3 Goals',
		dataPoint: HandballMSFNames.FirstTeamToScore3Goals,
		statusId: 2
	},
	[HandballMSFNames.FirstTeamToScore5Goals]: {
		msf: 'firstTeamToScore5',
		display: 'First Team to Score 5 Goals',
		dataPoint: HandballMSFNames.FirstTeamToScore5Goals,
		statusId: 3
	},
	[HandballMSFNames.FirstHalfScore]: {
		msf: 'firstHalf',
		display: 'First Half',
		dataPoint: HandballMSFNames.FirstHalfScore,
		statusId: 4
	},
	[HandballMSFNames.SecondHalfScore]: {
		msf: 'secondHalf',
		display: 'Second Half',
		dataPoint: HandballMSFNames.SecondHalfScore,
		statusId: 5
	},
	[HandballMSFNames.RegularTimeScore]: {
		msf: 'regularScore',
		display: 'Regular Score',
		dataPoint: HandballMSFNames.RegularTimeScore,
		statusId: 111
	},
	[HandballMSFNames.LastTeamToScore]: {
		msf: 'lastTeamToScore',
		display: 'Last Team to Score',
		dataPoint: HandballMSFNames.LastTeamToScore,
		statusId: 6
	}
};

export const HandballInitialScoreState: HandballScore = [{
	dataPoint: HandballMSFNames.FirstTeamToScore,
	result: [-1, -1]
}, {
	dataPoint: HandballMSFNames.FirstTeamToScore3Goals,
	result: [-1, -1]
}, {
	dataPoint: HandballMSFNames.FirstTeamToScore5Goals,
	result: [-1, -1]
}, {
	dataPoint: HandballMSFNames.FirstHalfScore,
	result: [-1, -1]
}, {
	dataPoint: HandballMSFNames.SecondHalfScore,
	result: [-1, -1]
}, {
	dataPoint: HandballMSFNames.RegularTimeScore,
	result: [-1, -1]
}, {
	dataPoint: HandballMSFNames.LastTeamToScore,
	result: [-1, -1]
}];

export const statusNames = {
	display: {
		1: 'First Team to Score',
		2: 'First Team to Score 3 Goals',
		3: 'First Team to Score 5 Goals',
		4: 'First Half',
		5: 'Second Half',
		111: 'Regular Score',
		6: 'Last Team to Score'
	}
};
