import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';

export const theme = createMuiTheme({
	palette: {
		primary: {
			main: green[400],
			light: green[300],
			dark: green[600],
			contrastText: '#f1f1f1'
		},
		secondary: {
			main: red[600],
			light: red[400],
			dark: red[800],
			contrastText: grey[50]
		},
		warning: {
			main: orange[500],
			light: orange[300],
			dark: orange[700],
			contrastText: grey[50]
		},
		error: {
			main: orange[500],
			light: orange[300],
			dark: orange[700],
			contrastText: grey[800]
		},
		info: {
			main: blue[500],
			light: blue[300],
			dark: blue[700],
			contrastText: grey[50]
		},
		success: {
			main: green[500],
			light: green[300],
			dark: green[700],
			contrastText: grey[800]
		}
	},
	typography: {
		htmlFontSize: 16,
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		h1: {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: 700,
			fontSize: '1.25rem',
			lineHeight: 1.6
		},
		caption: {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: 'bold',
			fontSize: '1.2rem',
			lineHeight: 1.5,
			letterSpacing: '0.00938em'
		},
		body1: {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: 500,
			fontSize: '0.875rem',
			lineHeight: 1.6,
			letterSpacing: '0.0075em'
		}
	}
});
