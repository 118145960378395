export interface DialogState {
	isOpen: boolean;
    component: string,
	props: any
}

export const DialogActionTypes = {
	showDialog: '@dialog/showDialog',
	hideDialog: '@dialog/hideDialog'
};
