import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import store from '../../store';

export interface SocketEvent {
	type: string;
	data: {
		page?: string;
		user?: any;
		value?: {
			idToken: string;
			eventId: string;
			sportId: number;
		};
	};
}

interface ConnectionEvent {
	type: string;
	connected: boolean;
}

const events: Subject<SocketEvent> = new Subject();
const connectionStatus: Subject<ConnectionEvent> = new Subject();
let socket: WebSocket;
let intervalId = null;

const logMessage = message => {
	console.log('Message =>', message, new Date().toISOString());
	console.groupEnd();
};

const notifyAll = event => {
	events.next(event);
};

const onOpen = message => {
	logMessage(message);
	connectionStatus.next({ type: 'open', connected: true });
	notifyAll({ type: 'open', data: null });
	clearInterval(intervalId);
};

const onMessage = (message: MessageEvent) => {
	logMessage(message);
	try {
		const data = JSON.parse(message.data);
		notifyAll({ type: data.type, data: data });
	} catch (e) {
		console.error('Socket on message operation encountered error: ', e.message);
		notifyAll({ type: 'error', data: e });
	}
};

const onClose = websocketUrl => {
	clearInterval(intervalId);
	intervalId = setInterval(() => resetSocket(websocketUrl, window.location.search), 1000);
	notifyAll({ type: 'closed', data: null });
};

const onError = err => {
	console.error('Socket encountered error: ', err, 'Closing socket');
	notifyAll({ type: 'error', data: err });
	socket && socket.close();
};

const addSubscription = (event: string, callback): Subscription => {
	return events.pipe(filter(({ type }) => type === event)).subscribe(({ data }) => callback(data));
};

const sendData = data => {
	try {
		const serializedData = JSON.stringify(data);
		if (socket && socket.readyState === 1) {
			socket.send(serializedData);
		}
	} catch (e) {
		notifyAll({ type: 'error', data: e.message || 'Unknown socket error' });
	}
};

const resetSocket = (websocketUrl: string, newURL: string): void => {
	if (socket && socket.readyState === 1) {
		socket.close();
	} else {
		if ((!socket) || (socket && socket.readyState === 3)) {
			const token = store.getState().auth.token;
			const username = store.getState().auth.username;
			initSocket(websocketUrl, newURL, '&user=' + username + '&token=' + token);
		}
	}
};

const closeSocket = () => {
	if (socket && socket.readyState === 1) {
		socket.close();
	}
};

const initSocket = (websocketUrl: string, socketURL = window.location.search, authQueryParam: string) => {
	socket = new WebSocket(websocketUrl + socketURL + authQueryParam);
	socket.onclose = onClose;
	socket.onopen = onOpen;
	socket.onmessage = onMessage;
	socket.onerror = onError;
};

export { addSubscription, sendData, closeSocket, initSocket };
