import { createSelector } from 'reselect';
import { RootState } from 'store';

export const selectCommandsState = (state: RootState) => state.commands;

export const selectCommand = createSelector([selectCommandsState], state => state.command);

export const selectCommandsLog = createSelector([selectCommandsState], state => state.commandLog);

export const selectCommandsId = createSelector([selectCommandsState], state => state.commandId);

export const selectCommandsInc = createSelector([selectCommandsState], state => state.commandInc);

export const selectMapIdToActionId = createSelector([selectCommandsState], state => state.mapIdToActionId);

export const selectLastMatchStatusFromCommands = createSelector([selectCommandsLog], logs => {
	const newLogs = [...logs];
	const lastState = newLogs.reverse().find( i => i.value?.key === 'matchstatus');
	return lastState ? lastState.value.value.status : '';
});
