import React from 'react';
import { RugbyLeagueKeys, RugbyLeagueScore } from './RugbyLeague.types';

export const initialScoreState: RugbyLeagueScore = {
	[RugbyLeagueKeys.FirstHalfScore]: { dataPoint: 'first_half_score', result: [-1, -1] },
	[RugbyLeagueKeys.SecondHalfScore]: { dataPoint: 'second_half_score', result: [-1, -1] },
	[RugbyLeagueKeys.FullScore]: { dataPoint: 'regular_time_score', result: [-1, -1] }
};

export const tableHeaders = [
	<span>1st<br />Half</span>,
	<span>2nd<br />Half</span>,
	<span>Full<br />Score</span>
];

export const statusNames = {
	display: {
		1: '1st half',
		2: '2nd half',
		111: 'Full Score'
	}
};

export const scorePointsByType = {
	Try: { label: 'Try', amount: 4 },
	Conversion: { label: 'Conversion', amount: 2 },
	OnePointDropGoal: { label: '1 Point Drop Goal', amount: 1 },
	TwoPointsDropGoal: { label: '2 Points Drop Goal', amount: 2 },
	Penaltygoal: { label: 'Penalty goal', amount: 2 },
	Penaltytry: { label: 'Penalty try', amount: 4 }
};
