import React from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@material-ui/core';

import { selectConfig } from 'store/config/config.selectors';

interface ConfirmBackDialogProps {
	dismiss: () => void;
}

const ConfirmBackDialog = ({ dismiss }: ConfirmBackDialogProps) => {
	const config = useSelector(selectConfig);

	const handleConfirm = () => {
		window.location.href = config.REACT_APP_RESULTING_MONITORING_URL;
	};

	return (
		<>
			<DialogTitle data-cy="confirm-back-dialog-title">Warning</DialogTitle>
			<DialogContent>
				<DialogContentText data-cy="confirm-back-dialog-text">Are you sure you want to go back?</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					aria-label="confirm"
					onClick={handleConfirm}
					variant="contained"
					color="primary"
					disableElevation
					data-cy="confirm-back-dialog-yes"
				>
					Yes
				</Button>
				<Button onClick={dismiss} variant="outlined" color="primary" disableElevation data-cy="confirm-back-dialog-no">
					No
				</Button>
			</DialogActions>
		</>
	);
};

export default ConfirmBackDialog;
