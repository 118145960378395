import React from 'react';
import { Switch } from 'react-router-dom';

import { SRTCollectionTool, SRTProtectedRoute } from './components';

const App = () => {
	return (
		<div className="App" data-testid='App'>
			<Switch>
				<SRTProtectedRoute exact path="/">
					<SRTCollectionTool />
				</SRTProtectedRoute>
			</Switch>
		</div>
	);
};

export default App;
