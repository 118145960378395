import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Paper, Typography, Box } from '@material-ui/core';

import { selectFixtureData } from 'store/sport/sport.selectors';

import { useSRTEventInfoStateStyles } from './SRTEventInfoStyles';
import { selectConfig } from './../../store/config/config.selectors';

const SRTEventInfo = () => {
	const fixtureData = useSelector(selectFixtureData);
	const classes = useSRTEventInfoStateStyles();
	const config = useSelector(selectConfig);

	return (
		<Grid container data-cy="srt-event-info">
			<Grid item xs={12} sm={8} md={5} lg={4}>
				<Paper className={classes.paper}>
					<Box display="flex">
						<Typography component="div" variant='caption' className={classes.title} data-cy="srt-event-info-competition">
							Competition:
						</Typography>
						<Box whiteSpace="nowrap" className={classes.value}>
							<a
								data-testid="competition-name"
								href={config.REACT_APP_ADMIN_TOOL_URL}
								target="_blank"
								rel="noopener noreferrer"
								data-cy="srt-event-info-competition-name"
							>
								{fixtureData?.competitionName || 'No Event Info'}
							</a>
						</Box>
					</Box>
					<Box data-testid="season-name" display="flex">
						<Typography component="div" variant='caption' className={classes.title} data-cy="srt-event-info-season">
							Season:
						</Typography>
						<Box whiteSpace="nowrap" className={classes.value} data-cy="srt-event-info-season-name">
							{fixtureData?.seasonName || 'No Event Info'}
						</Box>
					</Box>
					<Box data-testid="round-name" display="flex">
						<Typography component="div" variant='caption' className={classes.title} data-cy="srt-event-info-round">
							Round:
						</Typography>
						<Box whiteSpace="nowrap" className={classes.value} data-cy="srt-event-info-round-name">
							{fixtureData?.roundName || 'No Event Info'}
						</Box>
					</Box>
				</Paper>
			</Grid>
			<Grid item xs={6}sm={4} md={4} lg={4}>
				<Paper className={classes.paper}>
					<Typography align="center" data-cy="srt-event-info-competition-urls">Competition URLs:</Typography>
				</Paper>
			</Grid>
			<Grid item xs={6} sm={12} md={3} lg={4}	>
				<Paper className={classes.paper}>
					<Typography align="center" data-cy="srt-event-info-notes">Notes:</Typography>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default SRTEventInfo;
