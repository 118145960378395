import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button } from '@material-ui/core';

import { sportIds } from '../../common/utils/constants';
import { addSubscription, sendData } from 'common/utils/socket.utils';
import { useSearchParams } from '../../common/hooks/useSearchParams';
import { selectControlTaken } from '../../store/ui/ui.selectors';
import { selectAuthState } from '../../store/auth/auth.selectors';
import { selectConfig } from './../../store/config/config.selectors';

import { useSRTLineupsStyles } from './SRTLineupsStyles';

const SRTLineups = () => {
	const classes = useSRTLineupsStyles();
	const { eventId, sport } = useSearchParams();
	const { username } = useSelector(selectAuthState);
	const { isControlTaken } = useSelector(selectControlTaken);
	const config = useSelector(selectConfig);

	const subscription = useRef(null);

	useEffect( () => {
		subscription.current = addSubscription('matchState', data => {
			console.log(data);
		});

		// Return () => subscription.current && subscription.current.unsuscribe();
	}, []);

	const requestMatchState = () => {
		const obj = {
			type: 'matchState',
			user: username,
			page: 'resulting',
			value: {
				'event_id': eventId,
				'sport_id': sportIds[sport]
			}
		};

		sendData(obj);
	};

	return (
		<Grid container direction="row" justify="center" alignItems="flex-end" className={classes.root} data-cy="srt-lineups">
			<Grid item>
				<Button
					variant="contained"
					color="primary"
					size="medium"
					target="_blank"
					disabled={!isControlTaken}
					href={config.REACT_APP_SPOCOTO_LINEUPS_URL +
                        sport + '/databox_inline/eventFK/' + eventId}
					className={classes.leftButton}
					disableElevation
					aria-label="set-lineups"
					data-cy="srt-set-lineups"
				>
						Set LineUps
				</Button>
				<Button
					variant="contained"
					color="secondary"
					size="medium"
					disabled={!isControlTaken}
					onClick={requestMatchState}
					className={classes.rightButton}
					disableElevation
					aria-label="get-lineups"
					data-cy="srt-get-lineups"
				>
						Get Lineups
				</Button>
			</Grid>
		</Grid>
	);
};

export default SRTLineups;
