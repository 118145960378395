type DartBasicScore = {
    [key in DartsStatusIdKeys]: DataPoint
}

export type DartScore = DartBasicScore | {
    [key: string]: DataPoint
}

export enum DartsStatusIdKeys {
    FINAL_RESULT = '111',
    '180S_NUMBER' = '180',
}
