export enum AmericanFootballKeys {
	FirstQuarterScore = '1',
	SecondQuarterScore = '2',
	FirstHalfScore = '3',
	ThirdQuarterScore = '4',
	FourthQuarterScore = '5',
	SecondHalfScore = '6',
	RegularTimeScore = '111',
	OverTimeScore = '112',
	FinalScore = '113'
}

export type AmericanFootballScore = { [key in AmericanFootballKeys]: { dataPoint: string; result: Array<number>; }; };

/* eslint-disable camelcase */
export interface AmericanFootballDataPointsInterface {
	'1st_quarter_score': boolean,
	'2nd_quarter_score': boolean,
	first_half_score: boolean,
	'3rd_quarter_score': boolean,
	'4th_quarter_score': boolean,
	second_half_score: boolean,
	regular_time_score: boolean,
	overtime_score: boolean,
	final_score: boolean,
	goal_types: boolean,
	players_information: boolean
}

export type Result = [number, number];

export interface LineUpsItem {
	$type: string;
	internalId: string;
	playerPosition: string;
	position: any;
	shirtNumber: number;
	sourceId: string;
	sourceName: string;
}

export interface LineUpsData {
    home: any,
	away: any
}

export interface GoalType {
	player: string;
	minute: string | number,
	type: string,
	phase?: string
}

export interface GoalsList {
	home: {
		[key: number]: GoalType[]
	},
	away: {
		[key: number]: GoalType[]
	},
}

export type InEditMode = {
	status: boolean,
	columnKey: string
};

export interface GoalDataInterface {
	type: string,
	minute: string | number,
	label?: string,
	phase: string,
	player: string
}
