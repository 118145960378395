import { DialogActionTypes, DialogState } from './dialog.types';

export const initialState: DialogState = {
	isOpen: false,
	component: '',
	props: {}
};

export const dialogReducer = (state = initialState, action: any): DialogState => {
	switch (action.type) {
		case DialogActionTypes.showDialog: {
			return {
				...state,
				isOpen: true,
				component: action.payload.dialog,
				props: action.payload.props || {}
			};
		}
		case DialogActionTypes.hideDialog: {
			return {
				...state,
				isOpen: false,
				component: '',
				props: {}
			};
		}
		default:
			return state;
	}
};
