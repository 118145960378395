import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@material-ui/core';

import { sportIds, statusNameValues } from 'common/utils/constants';
import { useSearchParams } from 'common/hooks/useSearchParams';
import { sendData } from 'common/utils/socket.utils';
import { selectCommandsId, selectCommandsInc } from 'store/command/command.selectors';
import { selectAuthState } from 'store/auth/auth.selectors';
import { setSendStatus, toggleActionButtons } from 'store/ui/ui.actions';

interface ConfirmStatusDialogProps {
    status: string;
    dismiss: () => void;
}

const ConfirmStatusDialog = ({ status, dismiss }: ConfirmStatusDialogProps) => {
	const commandId = useSelector(selectCommandsId);
	const commandInc = useSelector(selectCommandsInc);
	const { username } = useSelector(selectAuthState);
	const dispatch = useDispatch();
	const statusIds = {
		Abandoned: 101,
		WinnerCompetitorOneByRetirementOrDefault: 102,
		WinnerCompetitorTwoByRetirementOrDefault: 103,
		WalkoverCompetitorOne: 104,
		WalkoverCompetitorTwo: 105
	};

	const { eventId, sport } = useSearchParams();

	const handleSaveStatus = () => {
		const key = 'matchstatus';
		const timestamp = new Date().toISOString();

		const obj = {
			type: 'command',
			user: username,
			page: 'resulting',
			value: {
				'id': commandId,
				'key': key,
				'sport_id': sportIds[sport],
				'value': { status },
				'isConfirmed': true,
				'status_id': statusIds[status],
				'event_id': eventId,
				'sequence': commandInc,
				'UTCtimestamp': timestamp,
				'feedAction': key,
				'feed': {
					'sequence': commandInc,
					'isConfirmed': true,
					'utcTimestamp': timestamp,
					'matchStatus': status
				}
			}
		};

		sendData(obj);
		dispatch(toggleActionButtons(true));
		dispatch(setSendStatus('success'));
		dismiss();
	};

	return (
		<>
			<DialogTitle data-cy="confirm-status-dialog-title">Are you sure you want to change the status of this event?</DialogTitle>
			<DialogContent>
				<DialogContentText data-cy="confirm-status-dialog-text">{`You are about to set the status of the event to ${(statusNameValues[status])}.Do you confirm this change?`}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					aria-label="confirm"
					onClick={handleSaveStatus}
					variant="contained"
					color="primary"
					disableElevation
					data-cy="confirm-status-dialog-yes"
				>
                    Yes
				</Button>
				<Button onClick={dismiss} variant="outlined" color="primary" disableElevation data-cy="confirm-status-dialog-no">
                    No
				</Button>
			</DialogActions>
		</>
	);
};

export default ConfirmStatusDialog;
