import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { SRTStatusControl } from '../../components';
import { sportIds } from '../../common/utils/constants';
import { sendData } from 'common/utils/socket.utils';
import { useSearchParams } from '../../common/hooks/useSearchParams';
import { useSRTEventControlsStyles } from './SRTEventControlsStyles';
import { selectActionButtonsState, selectControlTaken } from '../../store/ui/ui.selectors';
import { selectCommandsLog } from '../../store/command/command.selectors';
import { showDialog } from './../../store/dialog/dialog.actions';

interface SRTEventControlsProps {
	user: string;
}

const SRTEventControls = ({ user }: SRTEventControlsProps) => {
	const dispatch = useDispatch();
	const classes = useSRTEventControlsStyles();
	const { eventId, sport } = useSearchParams();
	const commandLog = useSelector(selectCommandsLog);
	const actionButtonsDisabled = useSelector(selectActionButtonsState);
	const { controlledBy, isControlTaken } = useSelector(selectControlTaken);

	const handleControlTaken = () => {
		if (controlledBy && !isControlTaken) {
			dispatch(showDialog({ dialog: 'ConfirmGetControlDialog', props: { controlledBy } }));
		} else {
			const obj: any = {
				type: 'setting',
				user,
				page: 'resulting',
				value: {
					key: isControlTaken ? 'releaseControl' : 'takeControl',
					'event_id': eventId,
					'sport_id': sportIds[sport],
					UTCtimestamp: new Date().toISOString()
				}
			};

			sendData(obj);
		}
	};

	return (
		<Grid
			container
			direction="row"
			justify="space-between"
			alignItems="center"
			className={classes.container}
			data-cy="srt-event-controls"
		>
			<Grid item xs={12} sm={6} md={4} className={classes.logGrid}>
				<Button
					id="btn-back"
					variant="outlined"
					color="primary"
					size="medium"
					className={classes.btnMargin}
					aria-label="back"
					data-cy="srt-event-controls-back-button"
					onClick={() => dispatch(showDialog({ dialog: 'ConfirmBackDialog' }))}
				>
						Back
				</Button>
				<Button
					id="btn-log"
					variant="outlined"
					color="primary"
					size="medium"
					className={classes.btnMargin}
					aria-label="logs"
					data-cy="srt-event-controls-logs-button"
					onClick={() =>
						dispatch(showDialog({ dialog: 'LogsDialog', props: { maxWidth: commandLog?.length > 0 ? 'lg' : 'sm' } }))}
				>
						Log
				</Button>
			</Grid>
			<Grid item xs={12} md={4} className={classes.controlGrid}>
				<Button
					variant="contained"
					color="primary"
					size="medium"
					disabled={actionButtonsDisabled}
					className={classes.btnMargin}
					onClick={handleControlTaken}
					disableElevation
					aria-label="control"
					data-cy="srt-event-controls-control-button"
				>
					{isControlTaken ? 'Release Control' : 'Take Control'}
				</Button>
			</Grid>
			<Grid item xs={12} sm={6} md={4} className={classes.statusGrid}>
				<SRTStatusControl disabled={!isControlTaken || actionButtonsDisabled} />
				<Button
					variant="contained"
					color="secondary"
					size="medium"
					disabled={!isControlTaken || actionButtonsDisabled}
					className={classes.resetButton}
					onClick={() => dispatch(showDialog({ dialog: 'ConfirmResetDialog' }))}
					disableElevation
					aria-label="reset"
					data-cy="srt-event-controls-reset-button"
				>
						Reset
				</Button>
			</Grid>
		</Grid>
	);
};

export default SRTEventControls;
