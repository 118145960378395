import { AnyAction } from 'redux';
import { CommandActionTypes } from './command.types';

export const setCommand = (command: any): AnyAction => ({
	type: CommandActionTypes.setCommand,
	payload: command
});

export const incrementCommandId = (actionId: any): AnyAction => ({
	type: CommandActionTypes.incrementComandId,
	payload: actionId
});

export const incrementCommandInc = (): AnyAction => ({
	type: CommandActionTypes.incrementCommandInc
});

export const addCommandToLog = (command: any) => ({
	type: CommandActionTypes.addToCommandLog,
	payload: command
});

export const resetCommandLog = () : AnyAction => ({
	type: CommandActionTypes.resetCommandLog
});

export const addCommandsAsBulk = (commands: Array<any>) : AnyAction => ({
	type: CommandActionTypes.addCommandsAsBulk,
	payload: commands
});
