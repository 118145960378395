import { AnyAction } from 'redux';
import { UIState, UIActionTypes } from './ui.types';

export const initialState: UIState = {
	loadingOverlayEnabled: true,
	isControlTaken: false,
	controlledBy: '',
	actionButtonsDisabled: false,
	sendStatus: 'none'
};

export const uiReducer = (state = initialState, action: AnyAction): UIState => {
	switch (action.type) {
		case UIActionTypes.setControlTaken: {
			const { isControlTaken, controlledBy } = action.payload;
			return {
				...state,
				isControlTaken,
				controlledBy
			};
		}
		case UIActionTypes.toggleLoadingOverlay: {
			return {
				...state,
				loadingOverlayEnabled: action.payload
			};
		}
		case UIActionTypes.toggleActionButtons: {
			return {
				...state,
				actionButtonsDisabled: action.payload
			};
		}
		case UIActionTypes.setSendStatus: {
			return {
				...state,
				sendStatus: action.payload
			};
		}
		default:
			return state;
	}
};
