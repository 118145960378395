import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Subscription } from 'rxjs';
import { useDispatch, useSelector } from 'react-redux';
import { Fade, Typography } from '@material-ui/core';
import { realEventIdRegex, sportIds, sportsMapping, testEventIdRegex, testAutomationEventIdRegex } from '../../common/utils/constants';
import { addSubscription, closeSocket, initSocket, sendData } from 'common/utils/socket.utils';
import { useSearchParams } from '../../common/hooks/useSearchParams';
import { SRTNavBar, SRTEventInfo, SRTEventControls, SRTLoader, SRTControlledBy } from '../../components';
import { setControlTaken, toggleLoadingOverlay } from 'store/ui/ui.actions';
import { setSportInfo, setInitialLoad } from 'store/sport/sport.actions';
import { setUsername } from 'store/auth/auth.actions';
import { selectAuthState } from 'store/auth/auth.selectors';
import {
	addCommandsAsBulk,
	addCommandToLog,
	incrementCommandId,
	incrementCommandInc,
	setCommand
} from '../../store/command/command.actions';
import { selectConfig } from './../../store/config/config.selectors';
import { fetchData, removeLowerCaseProps } from 'common/utils/fetch.utils';
import { cloneDeep } from 'lodash';
import { urlParamsValidators } from 'common/utils/validation.utils';

const SRTCollectionTool = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const { sport, eventId, page } = useSearchParams();
	const dispatch = useDispatch();
	const subscriptions = useRef<Subscription[]>([]);
	const socketId = useRef(null);
	const config = useSelector(selectConfig);
	const { username, token } = useSelector(selectAuthState);

	useEffect(() => {
		subscriptions.current.push(
			addSubscription('open', () => {
				setError(null);
				const obj = {
					type: 'load',
					page: 'resulting',
					value: {
						'event_id': eventId,
						'sport_id': sportIds[sport]
					}
				};
				sendData(obj);
			})
		);

		subscriptions.current.push(addSubscription('load', data => {
			if (isLoading) {
				socketId.current = data.num;
				const copied = cloneDeep(data);
				if (copied.matchState !== 'error') {
					copied.matchState = JSON.parse(copied.matchState);
				}
				if (copied.value) {
					copied.value = copied.value.split('\n');
					const { commands, settings } = parseSocketMessage(copied.value);
					if (settings.length) {
						const latestSetting = settings[settings.length - 1];

						dispatch(setControlTaken({
							isControlTaken: false,
							controlledBy: latestSetting.value.key === 'takeControl' ? latestSetting.user : ''
						}));
					}

					if (commands.length) {
						dispatch(addCommandsAsBulk(commands));
					}
				} else {
					copied.value = [];
				}

				if (realEventIdRegex.test(eventId)) {
					data?.fixtureData && data.fixtureData !=='error' && dispatch(
						setSportInfo({
							fixtureData: { ...data.fixtureData.fixtureData[0], dataPoints: data.fixtureData.dataPoints },
							matchState: data.matchState !== 'error' ? data.matchState : null
						})
					);

					if (data.fixtureData === 'error' || !data.fixtureData) {
						setError({ message: `Event ID ${eventId} does not exist` });
					} else if (Number(sportIds[sport]) !== data.fixtureData.fixtureData[0].sportId) {
						setError({ message: `Event ID ${eventId} does not match sport ${sport}` });
					}
				}

				setIsLoading(false);
				dispatch(setUsername( data?.user) );
				dispatch(toggleLoadingOverlay(false));
				dispatch(setInitialLoad('load'));
			}
		}));

		subscriptions.current.push(
			addSubscription('setting', data => {
				switch (data.value.key) {
					case 'takeControl':
						dispatch(setControlTaken({ isControlTaken: data.num === socketId.current, controlledBy: data.user }));
						break;
					case 'releaseControl':
						dispatch(setControlTaken({ isControlTaken: false, controlledBy: '' }));
						break;
					default:
						break;
				}
				if (data.type === 'error') {
					setError({ message: data.value });
				}
			})
		);

		subscriptions.current.push(
			addSubscription('command', data => {
				dispatch(setCommand(data));
				if (data.page === 'resulting' && data.type === 'command') {
					delete data.page;
					dispatch(addCommandToLog(data));
					if (!data.value.isModified && !data.value.isCancelled) {
						dispatch(incrementCommandId(data.actionId));
					}
					dispatch(incrementCommandInc());
				}
				if (data.type === 'error') {
					setError({ message: data.value });
				}
			})
		);

		subscriptions.current.push(
			addSubscription('error', data => {
				if (data.type === 'error') {
					setError({ message: data.value });
				}
			})
		);

		subscriptions.current.push(
			addSubscription('reset', () => {
				window.location.reload();
			})
		);

		subscriptions.current.push(
			addSubscription('close', () => {
				dispatch(toggleLoadingOverlay(true));
				setIsLoading(true);
			})
		);

		return () => {
			const oldSubscriptions = Object.assign([], subscriptions.current);
			oldSubscriptions.forEach(sub => sub.unsubscribe());
			subscriptions.current = [];
			closeSocket();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const params = { sport, eventId, page };
		const paramsKeys = Object.keys(params);
		const invalidKeys = paramsKeys.filter( key => !params[key]);
		const validPage = urlParamsValidators('page', page);
		const validSport = urlParamsValidators('sport', sport);
		const validEventId = urlParamsValidators('eventId', eventId);

		if (invalidKeys.length) {
			setError({ message: `Missing/Wrong mandatory URL param(s) ${invalidKeys.join(', ')}` });
		} else if (!validPage) {
			setError({ message: `Wrong URL param page "${page}"` });
		} else if (!validSport) {
			setError({ message: `Sport "${sport}" does not exist` });
		} else if (!validEventId) {
			setError({ message: `Event ID "${eventId}" does not exist` });
		} else {
			if (token !== null) {
				initSocket(config.REACT_APP_WEBSOCKET_URL, window.location.search, '&user=' + username + '&token=' + token );
			}
		}
	}, [sport, eventId, page, config.REACT_APP_WEBSOCKET_URL, token]);

	useEffect(() => {
		const getAllTestData = async () => {
			if (config.REACT_APP_ENV !== 'PROD') {
				try {
					const fixture = await fetchData('./test_events/' + sport + '.json');
					const { fixtureData, dataPoints } = Object.assign(fixture);
					dispatch(setSportInfo({ fixtureData: { ...fixtureData[0], dataPoints } }));
				} catch (e) {
					console.log(e);
				}

				try {
					const matchState = await fetchData('./test_events/MatchState' + sport + '.json');
					const modMatchState = removeLowerCaseProps(matchState);
					dispatch(setSportInfo({ matchState: modMatchState }));
				} catch (e) {
					console.log(e);
				}
			}
		};

		if (testEventIdRegex.test(eventId) || testAutomationEventIdRegex.test(eventId)) {
			getAllTestData();
		}
	}, [eventId, dispatch, sport, config.REACT_APP_ENV]);

	const parseSocketMessage = values => {
		let commands = [];
		const settings = [];
		for (const value of values) {
			if (value !== '') {
				const parsedData = JSON.parse(value);
				if (parsedData.page === 'resulting' && parsedData.type === 'command') {
					delete parsedData.page;
					commands = commands.concat(parsedData);
				} else if (parsedData.type === 'setting') {
					settings.push(parsedData);
				}
			}
		}
		return { commands, settings };
	};

	const sportCompData = sportsMapping[sport];

	if (error) {
		return <Typography className="fullPage" variant='h6'>{error.message}</Typography>;
	} else if (isLoading) {
		return <SRTLoader loadingText='Loading...'/>;
	} else {
		return (
			<Fade in>
				<>
					<SRTNavBar />
					<SRTEventInfo />
					<SRTEventControls user={username} />
					<SRTControlledBy user={username} />
					<Suspense fallback={<SRTLoader loadingText='Loading...'/>}>
						<sportCompData.component user={username} />
					</Suspense>
				</>
			</Fade>
		);
	}
};

export default SRTCollectionTool;
