/* eslint-disable camelcase */
export interface BaseballDataPointsInterface {
	inning_1: boolean,
	inning_2: boolean,
	inning_3: boolean,
	inning_4: boolean,
	inning_5: boolean,
	inning_6: boolean,
	inning_7: boolean,
	inning_8: boolean,
	inning_9: boolean,
	regular_time_score: boolean,
	extra_innings: boolean,
	final_score: boolean,
}

export enum BaseballDataPointsNames {
	extraInnings = 'extra_innings',
	regularTimeScore = 'regular_time_score',
	finalScore = 'final_score'
}
export interface BaseballInningScoreInterface {
	dataPoint: string,
	result: [number, number]
}

export enum BaseballCommandValueKey {
	gameScore = 'game_score',
	regularTimeScore = 'regular_time_score',
	finalScore = 'final_score'
}

export enum BaseballCommandKey {
	gameScore = 'gamescore',
	regularTimeScore = 'regulartimescore',
	finalScore = 'finalscore'
}

export enum BaseballStatusID {
	regularTimeScore = '111',
	finalScore = '112'
}

export type BaseballCommandValue = {
	[key: string] : number[]
}

export interface BaseballCommandInterface {
	value: {
		id: number,
		key: string,
		sport_id: string,
		value: BaseballCommandValue,
		feedAction: string,
		feed: {
			gameNumber: string,
			scoreInPoints: {
				competitorOne: number,
				competitorTwo: number
			}
		},
		status_id: string,
	}
}

