import React from 'react';
import { DartScore, DartsStatusIdKeys } from './Dart.types';

export const getHeaders = sets => {
	const headers = [];
	for (let i = 0; i < sets; i++) {
		switch (i) {
			case 0:
				headers[i] = <span>1st<br />Set</span>;
				break;
			case 1:
				headers[i] = <span>2nd<br />Set</span>;
				break;
			case 2:
				headers[i] = <span>3rd<br />Set</span>;
				break;
			case 20:
				headers[i] = <span>21st<br />Set</span>;
				break;
			default:
				headers[i] = <span>{(i + 1) + 'th'}<br />Set</span>;
				break;
		}
	}
	headers.push(<span>Final<br />Score</span>, '180s');
	return headers;
};

export const getPhaseOption = sets => {
	const headers = [];
	for (let i = 1; i <= sets; i++) {
		switch (i) {
			case 1:
			case 21:
				headers[i - 1] = [i, i + 'st'];
				break;
			case 2:
				headers[i - 1] = [i, i + 'nd'];
				break;
			case 3:
				headers[i - 1] = [i, i + 'rd'];
				break;
			default:
				headers[i - 1] = [i, i + 'th'];
				break;
		}
	}
	return headers;
};

export const getInitialScoreState = (sets: number): DartScore => {
	const initialScoreState = {};
	for (let i = 1; i <= sets; i++) {
		initialScoreState[i] = {
			dataPoint: 'set_score_' + i,
			result: [-1, -1]
		};
	}
	initialScoreState[DartsStatusIdKeys.FINAL_RESULT] = { dataPoint: 'final_result', result: [0, 0] };
	initialScoreState[DartsStatusIdKeys['180S_NUMBER']] = { dataPoint: '180s_number', result: [0, 0] };

	return initialScoreState;
};

export const getScoreToStatusId = sets => {
	const scoreToStatusId = [];
	for (let i = 0; i < sets; i++) {
		scoreToStatusId[i] = i + 1;
	}
	scoreToStatusId.push(111, 180);
	return scoreToStatusId;
};

//TODO type fixture data
export const getTeamsFromFixture = fixturecompetitors => {
	return [
		fixturecompetitors[0] ? fixturecompetitors[0]?.competitor?.name : 'No Team 1 Info',
		fixturecompetitors[1] ? fixturecompetitors[1]?.competitor?.name : 'No Team 2 Info'
	];
};

export const getSetsFromFixture = (fixtureData): number =>
	fixtureData ? fixtureData.fixtureproperty?.sets : 9;

/**
 * Calculate the validity of a darts data point score
 * @param point point tuble
 * @returns boolean
 */
export const validateScorePoint = (dataPoint: number[], maxScore = 3): boolean => {
	const [a, b] = dataPoint;
	// Sanity check
	return (
		a >= 0 &&
		b >= 0 &&
		((a === maxScore && b < maxScore) || (a < maxScore && b === maxScore))
	);
};

export const validateScorePointTiebreak = (dataPoint: number[], maxScore = 3): boolean => {
	const [a, b] = dataPoint;
	// Sanity check
	return (
		a >= 0 &&
		b >= 0 &&
		((a === maxScore && b < maxScore) || (a < maxScore && b === maxScore) ||
			(a > maxScore && a - b <= 2 && a > b) || (b > maxScore && b - a <= 2 && b > a))
	);
};

/**
 * Allows invalid score for current edited column if haven't added next game score column
 */
export const allowInvalidScore = (dataPoint: number[], maxScore = 3): boolean => {
	const [a, b] = dataPoint;
	return ((a <= maxScore && b <= maxScore) /* ||
		((a >= maxScore || b >= maxScore) && Math.abs(a - b) <= 2) */);
};

// This data points are already validated, if the needs come, just use the function above to validate :-|
export const calculateMatchScore = (score: DartScore): number[] => {
	/* Remove FinalScore and 180s from the array as we do not want to be counted */
	const newDataPoints = Object.values(score)
		.filter( i => i.dataPoint !== 'final_result' && i.dataPoint !== '180s_number');
	const length = newDataPoints.length;
	const newMatchScore: number[] = [0, 0];
	const maxScore = 3;
	for (const pos in newDataPoints) {
		const result = newDataPoints[pos].result;
		switch (pos) {
			case String(length - 1): {
				if (result[0] - result[1] === 0) {
					continue;
				}
				const higherScoreIndex = result[0] > result[1] ? 0 : 1;
				if (result[higherScoreIndex] === maxScore ||
					(result[higherScoreIndex] > maxScore && Math.abs(result[0] - result[1]) <= 2)) {
					newMatchScore[higherScoreIndex] += 1;
				}
				break;
			}
			default:
				if (result[0] === maxScore && result[0] - result[1] === 0) {
					continue;
				}
				if (result[0] === maxScore) {
					newMatchScore[0] += 1;
				}
				if (result[1] === maxScore) {
					newMatchScore[1] += 1;
				}
				break;
		}
	}

	return newMatchScore;
};

export const validateMatchScore = (matchScore: number[], sets: number): boolean => {
	return ((matchScore[0] + matchScore[1] <= sets) &&
		((matchScore[0] === Math.ceil(sets / 2) && matchScore[0] > matchScore[1] && matchScore[1] !== -1) ||
			(matchScore[1] === Math.ceil(sets / 2) && matchScore[1] > matchScore[0] && matchScore[0] !== -1)));
};

export const isDisabledEdit = (matchScore: number[], set: number, sets: number): boolean => {
	return !((matchScore[0] + matchScore[1] >= set && matchScore[0] < sets / 2 && matchScore[1] < sets / 2) ||
		matchScore[0] + matchScore[1] > set);
};

/* Restriction to be between 1 and 501 */
export const validLostPlayerPoints = (value): boolean => {
	return (Number(value) > 0 && Number(value) <= 501);
};

export const isValidSetsScore = (score: DartScore, sets: number): boolean => {
	const matchScore = score[DartsStatusIdKeys.FINAL_RESULT].result;
	const lastSetIndex = (matchScore[0] + matchScore[1]);
	if (lastSetIndex >= 0) {
		const lastSetScore = score[lastSetIndex].result;
		if (matchScore[0] === Math.ceil(sets / 2) && lastSetIndex === sets - 1) {
			return lastSetScore[0] >= 3 && lastSetScore[0] > lastSetScore[1];
		} else if (matchScore[1] === Math.ceil(sets / 2) && lastSetIndex === sets - 1) {
			return lastSetScore[1] >= 3 && lastSetScore[1] > lastSetScore[0];
		}
	}
	return true;
};

export const checkIfFinalResultisEditable = (dataPoints: any[]): boolean => {
	let isEditable = true;
	dataPoints.forEach(dataPoint => {
		if (dataPoint.data_point === 'set_score_1') {
			isEditable = false;
		}
	});
	return isEditable;
};

export const checkIf180sIsEditable = (dataPoints: any[]): boolean => {
	let isEditable = false;
	dataPoints.forEach(dataPoint => {
		if (dataPoint.data_point === '180s_number') {
			isEditable = true;
		}
	});
	return isEditable;
};

export const statusNames = {
	display: {
		1: '1st set',
		2: '2nd set',
		3: '3rd set',
		4: '4th set',
		5: '5th set',
		6: '6th set',
		7: '7th set',
		8: '8th set',
		9: '9th set',
		111: 'Final Score',
		180: '180s'
	}
};
