import React from 'react';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@material-ui/core';

interface SRTInfoProps {
	title: string;
	description?: string;
	button?: string;
	dismiss: () => void;
}

const SRTInfoDialog = ({ title, description, button = 'OK', dismiss }: SRTInfoProps) => {
	return (
		<>
			<DialogTitle data-cy="srt-info-dialog-title">{title}</DialogTitle>
			{description && (
				<DialogContent>
					<DialogContentText data-cy="srt-info-dialog-text">{description}</DialogContentText>
				</DialogContent>
			)}
			<DialogActions>
				<Button onClick={dismiss} variant="contained" color="primary" disableElevation data-cy="srt-info-dialog-ok">
					{button}
				</Button>
			</DialogActions>
		</>
	);
};

export default SRTInfoDialog;
