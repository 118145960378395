export type Result = [number, number];

export enum FootballKeys {
	FirstHalfScore = '1',
	SecondHalfScore = '2',
	FullTime = '111'
}

export type FootballScore = { [key in FootballKeys]: { dataPoint: string; result: Array<number>; }; };

export interface ScoreItem {
	dataPoint: string,
	result: Result
}

export type Score = ScoreItem[]

/* eslint-disable camelcase */
export interface FootballDataPointsInterface {
	first_half_score: boolean,
	second_half_score: boolean,
	full_time_score: boolean,
	goals_information: boolean,
	goal_types: boolean,
	players_information: boolean
}
/* eslint-enable camelcase */

export interface LineUpsItem {
	$type: string;
	internalId: string;
	playerPosition: string;
	position: any;
	shirtNumber: number;
	sourceId: string;
	sourceName: string;
}

export interface LineUpsData {
    home: {
        in: any,
        out: any
    },
	away: {
		in: any,
		out: any
	}
}

export interface GoalType {
	player: string;
	minute: string | number;
	type: string;
}

export interface GoalsList {
	home: {
		[key: number]: GoalType
	},
	away: {
		[key: number]: GoalType
	},
}

export interface ScoresData {
	score: FootballScore,
	goalsList: GoalsList
}

export type InEditMode = {
	status: boolean,
	columnKey: number
}

export interface Substitution {
	id: number;
	inNameId: string;
	outNameId: string;
	inName: string;
	outName: string;
	minute: string;
}

export interface SubstitutionListProps {
	team: string;
	substLog: Substitution[];
	isTakenControl: boolean;
	goalsData: {
		[key: number]: any
	};
}
