import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SRTLoader } from 'components';
import { selectAuthState } from '../../store/auth/auth.selectors';

const SRTProtectedRoute = ({ children, ...rest }) => {
	const { authorized } = useSelector(selectAuthState);

	return authorized ? <Route {...rest}> {children} </Route> : <SRTLoader loadingText='Authenticating...'/>;
};

export default SRTProtectedRoute;
