import React from 'react';
import { Grid, CircularProgress, Typography, Fade } from '@material-ui/core';

interface SRTLoaderProps {
	loadingText?: string;
}

const SRTLoader = ({ loadingText }: SRTLoaderProps) => {
	return (
		<div className="fullPage">
			<Grid container direction='column' justify="center" alignItems="center" data-cy="srt-loader">
				<Fade in>
					<>
						<CircularProgress data-cy="srt-loader-cicular-progress" />
						{loadingText && <Typography variant='h6' data-cy="srt-loader-typography">{loadingText}</Typography>}
					</>
				</Fade>
			</Grid>
		</div>
	);
};

export default SRTLoader;
