export interface CommandsState {
	command: any;
	commandLog: Array<any>;
	mapIdToActionId: { [key: number] : any},
	commandId: number;
	commandInc: number;
}

export const CommandActionTypes = {
	setCommand: '@command/setCommand',
	incrementComandId: '@command/incrementCommandId',
	incrementCommandInc: '@command/incrementCommandInc',
	addToCommandLog: '@command/addToCommandLog',
	resetCommandLog: '@command/resetCommandLog',
	addCommandsAsBulk: '@command/addCommandsAsBulk'
};
