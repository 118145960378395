import { makeStyles } from '@material-ui/core';

export const useIconButtonStyles = makeStyles(theme => ({
	iconBtn: {
		background: 'transparent !important',
		width: 50,
		minWidth: 50,
		'& span.MuiButton-startIcon': {
			margin: 0
		},
		'& .Mui-disabled': {
			background: '#f3f3f3',
			border: 'solid 1px #c3c3c3'
		},
		'& .MuiButton-iconSizeLarge > *:first-child': {
			fontSize: '2.1875rem'
		},
		'& .MuiButton-containedSizeSmall': {
			fontSize: '1.875rem'
		}
	},
	tooltip: {
		background: 'none',
		color: theme.palette.primary.main
	}
}));
