import { AnyAction } from 'redux';
import { ConfigState, ConfigActionTypes } from './config.types';

export const initialState: ConfigState = {
	data: {}
};

export const configReducer = (state = initialState, action: AnyAction): ConfigState => {
	switch (action.type) {
		case ConfigActionTypes.setConfig: {
			return {
				...state,
				data: action.payload
			};
		}
		default:
			return state;
	}
};
