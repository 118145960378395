import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useSearchParams } from '../../common/hooks/useSearchParams';
import { SRTInputCellProps } from './cells/SRTInputCell/SRTInputCell';
import { SRTCheckboxCellProps } from './cells/SRTCheckboxCell';
import { SRTSelectBoxCellProps } from './cells/SRTSelectBoxCell/SRTSelectBoxCell';
import { selectConfig } from './../../store/config/config.selectors';
import { useSRTFixtureTable } from './SRTFixtureTableStyles';

interface CellType {
	component: React.ElementType;
	props: SRTInputCellProps | SRTCheckboxCellProps | SRTSelectBoxCellProps;
}

interface Data {
	id: string;
	names: string[];
	header: any;
	competitors: CellType[][];
	actions: JSX.Element[];
}

interface Classes {
	redText?: string;
	greenText?: string;
	tableHeaderCells?: string;
	tableTeamCell?: string;
}

interface SRTFixtureTableProps {
	data: Data;
	classes?: Classes;
}

const SRTFixtureTable = ({ data, classes = {} }: SRTFixtureTableProps) => {
	const config = useSelector(selectConfig);

	const containerClasses = useSRTFixtureTable();
	const { eventId, sport } = useSearchParams();
	const headerCells = () => {
		const dataCells =
			!!data.header &&
			data.header.map((item, index) => (
				<TableCell
					data-testid={`titleSet ${index + 1}`}
					id={`titleSet${index + 1}`}
					variant='head'
					align='center'
					className={`${ item.sticky ? containerClasses.sticky: ''} ${containerClasses.tableHeaderCells} ${classes.tableHeaderCells}`}
					key={`${index + 1}_1`}
					data-cy={`srt-fixture-table-cell-titleSet ${index + 1}`}
				>
					{item.header ? item.header : item}
				</TableCell>
			));

		return (
			<>
				<TableCell
					data-testid="titleFixtureId"
					id="titleFixtureId"
					variant='head'
					className={`${containerClasses.sticky} ${containerClasses.tableHeaderCells} ${classes.tableHeaderCells}`}
					data-cy="srt-fixture-table-cell-fixture-id"
				>
					FixtureID:
					<a
						href={config.REACT_APP_SPOCOTO_URL +
							(sport === 'australianrules' ? 'aufootball' : sport) +
							'/edit/id/' + eventId}
						target="_blank"
						rel="noopener noreferrer nofollow"
						aria-label="fixture-id"
					>
						{data.id}
					</a>
				</TableCell>
				{dataCells}
			</>
		);
	};

	const tableCells =
		!!data.competitors &&
		data.competitors.map((competitor, compIndex) => (
			<TableRow key={compIndex} data-cy="srt-fixture-table-row">
				<>
					<TableCell
						variant='body'
						align='center'
						className={`${containerClasses.sticky} ${containerClasses.tableTeamCell} ${classes.tableTeamCell}`}
						data-testid={`competitor_${compIndex}_name`}
						id={`competitor_${compIndex}_name`}
						data-cy={`srt-fixture-table-competitor-${compIndex}-name`}
					>
						{data.names[compIndex]}
					</TableCell>
					{competitor.map(({ component: Cell, props: { value, sticky, ...restProps } }, cellIndex) => {
						return (
							<Cell
								key={`${cellIndex + 1}_${compIndex + 1}`}
								value={value === -1 ? '' : value}
								variant='body'
								align='center'
								className={`${ sticky ? containerClasses.sticky: ''} ${containerClasses.tableTeamCell} ${classes.tableTeamCell}`}
								{...restProps}
								data-cy="srt-fixture-table-cell-competitor"
							/>
						);
					})}
				</>
			</TableRow>
		));

	return (
		<div className={containerClasses.root}>
			<Paper className={containerClasses.container}>
				<TableContainer component={Paper} data-cy="srt-fixture-table-container">
					<Table className={containerClasses.table} data-cy="srt-fixture-table">
						<TableHead data-cy="srt-fixture-table-head">
							<TableRow data-cy="srt-fixture-table-row">{headerCells()}</TableRow>
						</TableHead>
						<TableBody>
							{tableCells}
							<TableRow data-cy="srt-fixture-table-row">
								<TableCell className={`${containerClasses.sticky} ${containerClasses.tableTeamCell}`} data-cy="srt-fixture-table-cell-actions" />
								{!!data.actions && data.actions}
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);
};

export default SRTFixtureTable;
