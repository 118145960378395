import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Paper, Typography, Fade } from '@material-ui/core';

import { selectControlTaken } from '../../store/ui/ui.selectors';

import { SRTControlledByStyles } from './SRTControlledByStyles';

const SRTControlledBy = ({ user }) => {
	const classes = SRTControlledByStyles();
	const { controlledBy } = useSelector(selectControlTaken);

	return (
		<Fade in>
			<Grid container data-cy="srt-controlled-by">
				<Grid item xs>
					<Paper className={classes.paper}>
						{controlledBy && (
							<Typography
								color={controlledBy !== user ? 'error' : 'primary'}
								align="center"
								variant='h6'
								data-cy="srt-controlled-by-typography"
							>
									Controlled by: {controlledBy}
							</Typography>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Fade>
	);
};

export default SRTControlledBy;
