import { SportActionTpes, SportState } from './sport.types';

export const initialState: SportState = {
	initialLoad: '',
	matchState: null,
	fixtureData: null
};

export const sportReducer = (state = initialState, action: any): SportState => {
	switch (action.type) {
		case SportActionTpes.setInitialLoad: {
			return {
				...state,
				initialLoad: action.payload
			};
		}
		case SportActionTpes.setSportInfo: {
			return {
				...state,
				...action.payload
			};
		}
		default:
			return state;
	}
};
