import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography, DialogTitle, DialogContent, DialogActions, Button, Grid, Paper,
	Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { selectCommandsLog } from 'store/command/command.selectors';
import { useSearchParams } from 'common/hooks/useSearchParams';
import { getSportStatusName } from 'common/utils/constants';

import { useDialogStyles } from '../DialogsStyles';
import { useLogsDialogStyles } from './LogsDialogStyle';

interface LogsDialogProps {
	dismiss: () => void;
}

const LogsDialog = ({ dismiss }: LogsDialogProps) => {
	const [extendedView, setExtendedView] = useState(false);
	const commandLog = useSelector(selectCommandsLog);
	const { sport } = useSearchParams();
	const dialogClasses = useDialogStyles();
	const logsDialogClasses = useLogsDialogStyles();

	const handleChange = () => {
		setExtendedView(!extendedView);
	};

	const LogsTable = ({ rows }) => {
		return (<Grid container direction="row" justify="center" alignItems="center">
			<Grid item xs>
				<TableContainer component={Paper} data-cy="logs-dialog-table-container">
					<Table data-cy="logs-dialog-table">
						<TableHead data-cy="logs-dialog-table-head">
							<TableRow data-cy="logs-dialog-table-row">
								<TableCell className={logsDialogClasses.tableHeaderCells} align="left" data-cy="logs-dialog-cell-timestamp">
									Timestamp
								</TableCell>
								<TableCell className={logsDialogClasses.tableHeaderCells} align="left" data-cy="logs-dialog-cell-id">
									Id
								</TableCell>
								<TableCell className={logsDialogClasses.tableHeaderCells} align="left" data-cy="logs-dialog-cell-status">
									Status
								</TableCell>
								<TableCell className={logsDialogClasses.tableHeaderCells} align="left" data-cy="logs-dialog-cell-type">
									Type
								</TableCell>
								<TableCell className={logsDialogClasses.tableHeaderCells} align="left" data-cy="logs-dialog-cell-result">
									Result
								</TableCell>
								<TableCell className={logsDialogClasses.tableHeaderCells} align="left" data-cy="logs-dialog-cell-username">
									Username
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
		);
	};

	const logsData = useMemo(() => {
		const rawData = [];
		const tableRows = [];
		commandLog.forEach((log, i) => {
			const result = Object.entries(log.value.value);
			rawData.push(
				<Typography gutterBottom variant="body1" key={i} data-cy="logs-dialog-typography-json">
					{JSON.stringify(log, null, ' ')}
				</Typography>);
			tableRows.push(
				<TableRow key={i} data-cy="logs-dialog-table-row">
					<TableCell variant='body' align="left" data-cy="logs-dialog-cell-timestamp">{log.value.UTCtimestamp}</TableCell>
					<TableCell variant='body' align="left" data-cy="logs-dialog-cell-id">{log.value.id}</TableCell>
					<TableCell variant='body' align="left" data-cy="logs-dialog-cell-status">
						{getSportStatusName(sport, log.value.status_id)}
					</TableCell>
					<TableCell variant='body' align="left" data-cy="logs-dialog-cell-type">{log.value.key}</TableCell>
					<TableCell variant='body' align="left" data-cy="logs-dialog-cell-result">{`${result[0][0]}: ${JSON.stringify(result[0][1])}`}</TableCell>
					<TableCell variant='body' align="left" data-cy="logs-dialog-cell-username">{log.user}</TableCell>
				</TableRow>
			);
		});

		return { raw: rawData, table: <LogsTable rows={tableRows}/> };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [commandLog]);

	return (
		<>
			{commandLog?.length > 0 &&
				<DialogTitle disableTypography className={dialogClasses.dialogTitle} data-cy="logs-dialog-title">
					<h2>Logs for event:</h2>
					<FormControlLabel
						control={
							<Switch
								aria-label="extended-view"
								checked={extendedView}
								onChange={handleChange}
								name="extendedView"
								color="primary"
								data-cy="logs-dialog-switch-extended-view"
							/>
						}
						label="Extended view"
					/>
				</DialogTitle>
			}

			<DialogContent>
				{commandLog?.length > 0
					? extendedView
						? logsData.raw
						: logsData.table
					: <Typography variant="h6" align='center' data-cy="logs-dialog-typography-no-logs">No logs for this event</Typography>
				}
			</DialogContent>

			<DialogActions>
				<Button onClick={dismiss} variant="outlined" color="primary" disableElevation data-cy="logs-dialog-ok">
                    OK
				</Button>
			</DialogActions>
		</>);
};

export default LogsDialog;
