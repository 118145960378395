export interface SportState {
	initialLoad: string;
    fixtureData: any,
    matchState: any
}

export const SportActionTpes = {
	setInitialLoad: '@sport/setInitialLoad',
	setSportInfo: '@sport/setSportInfo'
};
