export enum BasketballKeys {
	FirstTeamToScore = '1',
	FirstQuarterScore = '2',
	SecondQuarterScore = '3',
	FirstHalfScore = '4',
	ThirdQuarterScore = '5',
	FourthQuarterScore = '6',
	SecondHalfScore = '7',
	FullTimeScore = '111',
	FinalScore = '112',
	LastTeamToScore = '113'
}

export type BasketballScore = { [key in BasketballKeys]: { dataPoint: string; result: Array<number>; }; };
