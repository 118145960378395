import React from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

import { useSearchParams } from '../../common/hooks/useSearchParams';
import { selectFixtureData } from 'store/sport/sport.selectors';

import { useSRTNavBarStyles } from './SRTNavBarStyles';

const SRTNavBar = () => {
	const fixtureData = useSelector(selectFixtureData);

	const classes = useSRTNavBarStyles();
	const { sport } = useSearchParams();

	const match = fixtureData ? `${fixtureData.name} - ${fixtureData.startDate}` : 'No Event Info';

	return (
		<div className={classes.root}>
			<AppBar position="static" data-cy="srt-app-bar">
				<Toolbar variant="dense" data-cy="srt-toolbar">
					<img
						src={process.env.PUBLIC_URL + '/logos/' + sport + '.png'}
						className={classes.logo}
						alt="fireSpot"
						data-cy="srt-navbar-image"
					/>
					<Typography variant="h5" data-cy="srt-navbar-typography">{match}</Typography>
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default SRTNavBar;
