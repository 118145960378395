import React from 'react';
import { TableCell, Checkbox } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export interface SRTCheckboxCellProps {
	id: string;
	value?: string | number;
	sticky?: boolean;
	inEditMode: boolean;
	handleChange: (value: number) => void;
	handleSave: () => void;
	handleCancel: () => void;
	isDisabledSave: boolean;
	className?: string;
	valueClassName?: string;
	variant?: 'head' | 'body' | 'footer';
	align?: any;
}

const SRTCheckboxCell = ({
	id,
	value,
	inEditMode,
	handleChange,
	handleSave,
	handleCancel,
	isDisabledSave,
	className = '',
	align = 'center',
	variant
}: SRTCheckboxCellProps) => {

	return (
		<TableCell data-testid={id} id={id} className={className} variant={variant} align={align} data-cy={`${id}-checkbox-cell`}>
			{inEditMode ? (
				<Checkbox
					onChange={e => handleChange(e.target.checked === true ? 1 : 0)}
					onKeyUp={e => {
						if (e.key === 'Enter' && !isDisabledSave) {
							handleSave();
						}
					}}
					onKeyDown={e => {
						if (e.key === 'Escape') {
							handleCancel();
						}
					}}
					checked={value === 1}
					color="primary"
					data-cy={id}
				/>
			) : (
				value === 1 ? <CheckCircleIcon aria-label="icon-checked" color="primary" data-cy="srt-checkbox-cell-checked" /> : null
			)}
		</TableCell>
	);
};

export default SRTCheckboxCell;
