import { AnyAction } from 'redux';
import { AuthActionTypes } from './auth.types';

export const setAuthorized = (): AnyAction => ({
	type: AuthActionTypes.setAuthorized
});

export const setUsername = ( username: string ) => ({
	type: AuthActionTypes.setUsername,
	payload: username
});

export const setAuth0Token = ( token: string ) => ({
	type: AuthActionTypes.setAuth0Token,
	payload: token
});

export const setOrganizationId = ( organizationId: string ) => ({
	type: AuthActionTypes.setOrganizationId,
	payload: organizationId
});
