export enum RugbyUnionKeys {
	FirstHalf = '1',
	SecondHalf = '2',
	RegularScore = '111'
}
export interface RubgyUnionDataPointsInterface {
	/* eslint-disable camelcase */
	first_half_score: boolean,
	second_half_score: boolean,
	regular_time_score: boolean,
	goal_types: boolean,
	/* eslint-enable camelcase */
}

export type RugbyUnionScore = {
	[key in RugbyUnionKeys]: {
		dataPoint: string;
		result: Array<number>;
	};
};

export type RugbyUnionStatusMap = {
	[key in RugbyUnionKeys] : {
		msf: string,
		display: string,
		dataPoint: string
	}
}
export interface RugbyUnionGoal {
	type: 'conversion' | 'try' | 'dropGoal' | 'penalty' | '',
	minute: string
}

export type RugbyUnionGoalList = {
	[key in RugbyUnionKeys]: Array<RugbyUnionGoal>
}
