import { createSelector } from 'reselect';
import { RootState } from 'store';

export const selectUIState = (state: RootState) => state.ui;

export const selectActionButtonsState = createSelector([selectUIState], state => state.actionButtonsDisabled);

export const selectControlTaken = createSelector([selectUIState], state => {
	return {
		isControlTaken: state.isControlTaken, controlledBy: state.controlledBy
	};
});

export const selectSendStatusState = createSelector([selectUIState], state => state.sendStatus);
