export enum MotorsportKeys {
	RacePositions = '1',
	QualificationResult = '2',
	FastestLap = '3',
	FirstLapLeader = '4',
	RaceResult = '5'
}

export interface RaceCompetitor {
	id: string,
	name: string
}

export interface InitialTableState {
	[MotorsportKeys.QualificationResult]: { dataPoint: string, value: string },
	[MotorsportKeys.FastestLap]: { dataPoint: string, value: string },
	[MotorsportKeys.FirstLapLeader]: { dataPoint: string, value: string },
	[MotorsportKeys.RaceResult]: { dataPoint: string, value: string },
}

export interface StatusNames {
	display: {
		[key in MotorsportKeys]: string;
	}
}

/* eslint-disable camelcase */
export interface MotorsportDataPointsInterface {
	race_positions: boolean,
	qualification_result: boolean,
	fastest_lap: boolean,
	first_lap_leader: boolean,
	race_result: boolean
}
/* eslint-enable camelcase */
