export interface AuthState {
	authorized: boolean;
	username: string;
	token: string;
	organizationId: string;
}

export const AuthActionTypes = {
	setAuthorized: '@auth/setAuthorized',
	setUsername: '@auth/setUsername',
	setAuth0Token: '@auth/setAuth0Token',
	setOrganizationId: '@auth/setOrganizationId'
};
