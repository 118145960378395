import { TennisScore, TennisStatusIdKeys } from './Tennis.types';

export const getHeaders = sets => {
	const headers = [];
	for (let i=0; i < sets; i++) {
		switch (i) {
			case 0 :
				headers[i] = '1st';
				break;
			case 1 :
				headers[i] = '2nd';
				break;
			case 2 :
				headers[i] = '3rd';
				break;
			default:
				headers[i] = (i + 1) + 'th';
				break;
		}
	}
	headers.push('Match Score', 'Game Score');
	return headers;
};

export const keyToDataPointKey = [
	'first_set_score',
	'second_set_score',
	'third_set_score',
	'fourth_set_score',
	'fifth_set_score'
];

export const getInitialScoreState = (sets: number): TennisScore => {
	const initialScoreState = {};
	for (let i=1; i <= sets; i++) {
		initialScoreState[i] = {
			dataPoint: keyToDataPointKey[i - 1],
			result: [-1, -1]
		};
	}

	initialScoreState[TennisStatusIdKeys.MATCH_SCORE] = { dataPoint: 'match_score', result: [0, 0] };
	return initialScoreState;
};

export const getTeamsFromFixture = fixturecompetitors => {
	return [
		fixturecompetitors[0] ? fixturecompetitors[0]?.competitor?.name : 'No Team 1 Info',
		fixturecompetitors[1] ? fixturecompetitors[1]?.competitor?.name : 'No Team 2 Info'
	];
};

export const getSetsFromFixture = (fixtureData): number =>
	fixtureData ? fixtureData.fixtureproperty?.sets : 5;

export const getFinalSetType = (finalSet: string): string => {
	let finalSetType = '';

	switch (finalSet) {
		case 'Advantage' :
			finalSetType = 'Advantage';
			break;
		default:
			finalSetType = 'Tie Break';
			break;
	}

	return finalSetType;
};

/**
 * Calculate the validity of a table tennis data point score
 * @param point point tuble
 * @returns boolean
 */
// eslint-disable-next-line complexity
export const validateScorePoint = (dataPoint: number[], invalidScore, lastSetType: string): boolean => {
	const [a, b] = dataPoint;
	const maxScore = calculateMaxScore([a, b], 7, lastSetType);
	let valid = false;

	if (maxScore === 7) {
		valid = (a >= 0 && b >= 0 &&
		(invalidScore ||
		(((a === maxScore || b === maxScore) && (a < maxScore && b < maxScore) && Math.abs(a - b) === 2) ||
			(a === maxScore && b < maxScore && Math.abs(a - b) <= 2) ||
			(a < maxScore && b === maxScore && Math.abs(b - a) <= 2)))
		);
	} else if (maxScore === 6) {
		valid = (a >= 0 && b >= 0 &&
		(invalidScore ||
		(((a >= maxScore || b >= maxScore) && Math.abs(a - b) === 2) ||
			(a === maxScore && b < maxScore && Math.abs(a - b) >= 1) ||
			(a < maxScore && b === maxScore && Math.abs(b - a) >= 1)))
		);
	} else if (maxScore === 999) {
		valid = (((a === 7 || b === 7) && (Math.abs(a - b) <= 2)) ||
			(((a === 6 && b >= 6) || (b === 6 && a >= 6)) && (Math.abs(a - b) <= 2)) ||
			((a <= 6 || b <= 6) && (a >= 0 && b >= 0) && (a < 7 && b < 7)) ||
			(a > 7 || b > 7) && (Math.abs(a - b) <= 2));
	}

	return valid;
};

export const validateAbandonedScore = (abandonedScore, isTieBreakGame: boolean): boolean => {
	const [a, b] = abandonedScore;

	if (isTieBreakGame) {
		return (a >= 0 && b >= 0 &&
			((a < 7 && b < 7) || ((a >= 7 || b >= 7) && Math.abs(a - b) <= 1)));
	} else {
		return (a !== '-1' && b !== '-1' &&
			(((a === '40A' && b === '40') || (b === '40A' && a === '40')) || (a !== '40A' && b !== '40A')));
	}
};

/**
 * Allows invalid score for current edited column if haven't added next set score column
 */
export const allowInvalidScore = (dataPoint: number[]) : boolean => {
	const [a, b] = dataPoint;
	const maxScore = calculateMaxScore([a, b], 7);
	let allowed = false;

	if (maxScore === 7) {
		allowed = ((a <= maxScore && b < maxScore) && (Math.abs(a - b) <= 2)) ||
		((b <= maxScore && a < maxScore) && (Math.abs(b - a) <= 2));
	} else if (maxScore === 6) {
		allowed = ((a <= maxScore && b <= maxScore) ||
		((a === maxScore || b === maxScore) && (Math.abs(a - b) <= 2)));
	}

	return allowed;
};

export const calculateMaxScore = (result: number[], maxScore = 7, lastSetType = 'Tie Break') : number => {
	if (lastSetType === 'Advantage') {
		maxScore = 999;
	} else {
		if ((result[0] === 6 || result[1] === 6) &&
			(result[0] - result[1] >= 2 || result[1] - result[0] >= 2) && (result[0] < 7 && result[1] < 7)) {
			maxScore = 6;
		} else if ((result[0] <= 5 && result[1] <= 4) || (result[1] <= 5 && result[0] <= 4)) {
			maxScore = 6;
		}
	}

	return maxScore;
};

// This data points are already validated, if the needs come, just use the function above to validate :-|
/* eslint-disable complexity */
export const calculateMatchScore = (score: TennisScore, lastSetType: string): number[] => {
	const newScoreValues = Object.values(score).filter( p => p.dataPoint !== 'match_score');

	const newMatchScore = [0, 0];
	for (const pos in newScoreValues) {
		const result = newScoreValues[pos].result;
		const maxScore = calculateMaxScore(result, 7);
		const isFinalSetAdvantage = lastSetType === 'Advantage' && (parseInt(pos) + 1) === newScoreValues.length;

		if (maxScore === 7) {
			if ((result[0] === maxScore && (result[0] - result[1] === 1 || result[0] - result[1] === 2) && !isFinalSetAdvantage) ||
				(result[0] >= maxScore && (result[0] - result[1] === 2) && isFinalSetAdvantage)) {
				newMatchScore[0] += 1;
			} else if ((result[1] === maxScore && (result[1] - result[0] === 1 || result[1] - result[0] === 2) && !isFinalSetAdvantage) ||
				(result[1] >= maxScore && (result[1] - result[0] === 2) && isFinalSetAdvantage)) {
				newMatchScore[1] += 1;
			}
		} else if (maxScore === 6) {
			if (result[0] >= maxScore && result[0] - result[1] >= 2) {
				newMatchScore[0] += 1;
			} else if (result[1] >= maxScore && result[1] - result[0] >= 2) {
				newMatchScore[1] += 1;
			}
		}
	}

	return newMatchScore;
};

export const validateMatchScore = (matchScore: number[], sets: number): boolean => {
	return ((matchScore[0] + matchScore[1] <= sets) &&
		((matchScore[0] === Math.ceil(sets / 2) && matchScore[0] > matchScore[1] && matchScore[1] !== -1) ||
		(matchScore[1] === Math.ceil(sets / 2) && matchScore[1] > matchScore[0] && matchScore[0] !== -1)));
};

export const isDisabledEdit = (matchScore: number[], set: number, sets: number): boolean => {
	return !((matchScore[0] + matchScore[1] >= set && matchScore[0] < sets / 2 && matchScore[1] < sets / 2) ||
            matchScore[0] + matchScore[1] > set);
};

export const isValidSetsScore = (score: TennisScore, sets: number): boolean => {
	const matchScore = score[TennisStatusIdKeys.MATCH_SCORE].result;
	const lastSetIndex = (matchScore[0] + matchScore[1]) === 0 ? -1 : (matchScore[0] + matchScore[1]);
	if (lastSetIndex > -1) {
		const lastSetScore = score[lastSetIndex].result;
		if (lastSetIndex === sets) {
			if (matchScore[0] === Math.ceil(sets / 2)) {
				return lastSetScore[0] >= 6 && lastSetScore[0] > lastSetScore[1];
			} else {
				return lastSetScore[1] >= 6 && lastSetScore[1] > lastSetScore[0];
			}
		}
		return true;
	} else {
		return true;
	}
};

export const statusNames = {
	display: {
		1: '1st',
		2: '2nd',
		3: '3rd',
		4: '4th',
		5: '5th',
		111: 'Match Score',
		10: 'Gamе Score'
	}
};
