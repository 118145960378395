import { useLocation } from 'react-router-dom';

export const useSearchParams = () => {
	const location = useLocation();

	const getSearchParams = (search: string) => {
		const params: URLSearchParams = new URLSearchParams(search);
		return {
			sport: params.get('sport'),
			eventId: params.get('event_id'),
			page: params.get('page')
		};
	};

	const { sport, eventId, page } = getSearchParams(location.search);
	return { url: location.search, sport, eventId, page };
};
