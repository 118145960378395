export enum VolleyballKeys {
	FirstSet = '1',
	SecondSet = '2',
	ThirdSet = '3',
	FourthSet = '4',
	FifthSet = '5',
	FinalScore = '111'
}

export type VolleyballScore = { [key in VolleyballKeys]: { dataPoint: string; result: Array<number>; }; };
