import { AnyAction } from 'redux';
import { DialogActionTypes } from './dialog.types';

export const showDialog = (payload): AnyAction => {
	return {
		type: DialogActionTypes.showDialog,
		payload
	};
};

export const hideDialog = (): AnyAction => {
	return {
		type: DialogActionTypes.hideDialog
	};
};
