export enum AussieRulesStatusIds {
    FIRST_TEAM_TO_SCORE = 0,
    FIRST_QUARTER_SCORE,
    SECOND_QUARTER_SCORE,
    FIRST_HALF_SCORE,
    THIRD_QUARTER_SCORE,
    FOURTH_QUARTER_SCORE,
    SECOND_HALF_SCORE,
    REGULAR_SCORE = 111
}

export type AussieRulesScore = {
    [key in AussieRulesStatusIds]: DataPoint;
}
